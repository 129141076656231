<template>
  <div class="user-profile">
    <b-card>
      <div>
        <div class="description mb-0 text-right">
          <a href="#" v-b-popover.hover.top="$t('share_heading')" class="fa-solid fa-share-nodes p-0" :id="popover_id" onclick="return false"/>
          <b-popover :target="popover_id" triggers="focus" placement="bottom">
            <template slot="title">{{ $t('share_profile') }}</template>
            <ShareNetwork
              network="facebook"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-facebook-square fa-lg ml-3" />
            </ShareNetwork>
            <ShareNetwork
              network="linkedin"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-linkedin fa-lg ml-3" />
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-twitter-square fa-lg ml-3" />
            </ShareNetwork>
            <ShareNetwork
              network="whatsapp"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-whatsapp fa-lg ml-3" />
            </ShareNetwork>
            <ShareNetwork
              network="reddit"
              :url="route"
              :title="$t('checkout_profile')"
              hashtags="LovingYourWork,AnyTask"
            >
              <i class="fa-brands fa-reddit fa-lg ml-3" />
            </ShareNetwork>
          </b-popover>
          <b-button v-if="!isOwnProfile" variant="link" v-on:click="report()" v-b-modal.modal-report-1 class="p-0 pl-2" v-b-popover.hover.top="$t('report_type_user')">
            <i class="fa-solid fa-flag" aria-hidden="true" />
          </b-button>
        </div>
        <div class="profile-image mb-2 cursor-pointer" @click="goToUser">
          <b-img v-if="image_path" rounded="circle" :src='formatImageIcon(image_path)' />
          <span v-if="!image_path" class="fa-stack fa-5x" style="z-index: 1">
              <i class="fa-solid fa-circle fa-stack-2x text-gray" />
              <i class="fa-solid fa-user fa-stack-1x text-light" />
          </span>
        </div>
        <div class="user-label">
          <div class="username d-flex flex-column justify-content-center mb-1">
            <h3 class="m-0 font-weight-bold cursor-pointer" @click="goToUser">{{ username }}</h3>
          </div>
          <div v-if="online || rating <= 0" class="d-flex justify-content-center align-items-center">
            <span v-if="online" class="user-online position-relative badge badge-success mx-1">{{ $t('online') }}</span>
            <ObjectLabel v-if="rating <= 0" type="new" class="mx-1" :object="{ 'user': { 'review_count': rating }}"></ObjectLabel>
          </div>
        </div>

        <div v-if="rating > 0" class="pl-0 mb-0 mt-2 pb-0 rating">
          <ul class="mb-0 pl-0">
            <li>
              <i v-bind:class="{'fa-regular fa-star' : (rating < 0.5), 'fa-regular fa-star-half-stroke' : ( 0.5 <= rating & rating < 1 ) , 'fa-solid fa-star' : ( 1 <= rating )}" aria-hidden="true"/>
            </li>
            <li>
              <i v-bind:class="{'fa-regular fa-star' : (rating < 1.5), 'fa-regular fa-star-half-stroke' : ( 1.5 <= rating & rating < 2 ) , 'fa-solid fa-star' : (2 <= rating )}" aria-hidden="true"/>
            </li>
            <li>
              <i v-bind:class="{'fa-regular fa-star' : (rating < 2.5), 'fa-regular fa-star-half-stroke' : ( 2.5 <= rating & rating < 3 ) , 'fa-solid fa-star' : (3 <= rating )}" aria-hidden="true"/>
            </li>
            <li>
              <i v-bind:class="{'fa-regular fa-star' : (rating < 3.5), 'fa-regular fa-star-half-stroke' : ( 3.5 <= rating & rating < 4 ) , 'fa-solid fa-star' : ( 4 <= rating )}" aria-hidden="true"/>
            </li>
            <li>
              <i v-bind:class="{'fa-regular fa-star' : (rating < 4.5), 'fa-regular fa-star-half-stroke' : ( 4.5 <= rating & rating < 5 ) , 'fa-solid fa-star' : ( 5 <= rating )}" aria-hidden="true"/>
              <strong> {{Number(rating).toFixed(1)}} </strong>
              ({{ reviews.toLocaleString() }} {{ $t("reviews") }})
            </li>
          </ul>
        </div>
        <div class="user-description mt-3 pb-1 mb-0 info">
          <div class="description pt-3">
            <div id="user-summary-description" class="prewrap" v-html="this.$sanitize(shortDescription) === 'null' ? '' : this.$sanitize(shortDescription)"/>
          </div>
        </div>
        <b-button v-if="messageSwitchCheck()" block variant="primary" @click="contact">{{$t('contact_me')}}</b-button>
        <MessagePopup v-if="messageSwitchCheck()" id="contactModal" :user-name="username" :user-image="image_path" :user-hash="hash" :user-last-active="lastActive"/>
      </div>
    </b-card>
    <LoginModal :reason="$t( 'login_reason_contact')" id="loginContact"/>
    <Report :type="$t('user').toLowerCase()" :refId="screen + hash" :options="$t('user_report_reasons')" :hash="hash"/>
  </div>
</template>

<script>
import MessagePopup from '../Modal/MessagePopup.vue'
import Report from '../Task/Report.vue'
import LoginModal from '../Global/Login'
import helper from '../../helper'
import config from '../../config'
import ObjectLabel from '../Global/Label'

export default {
  name: 'UserProfile',
  props: {
    hash: String,
    username: String,
    online: Boolean,
    shortDescription: String,
    rating: Number,
    reviews: Number,
    verify: Number,
    screen: {
      type: String,
      required: false,
      default: 'desktop'
    },
    buttons: {
      type: Boolean,
      required: false,
      default: false
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    },
    tasks: {
      type: Boolean,
      required: false,
      default: false
    },
    image_path: String,
    popover_id: String,
    useMeta: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean,
      default: true
    },
    lastActive: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    ObjectLabel,
    Report,
    MessagePopup,
    LoginModal
  },
  metaInfo () {
    return {
      title: [
        this.useMeta ? 'Find freelance services from ' + this.username + ' - AnyTask.com' : undefined
      ],
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.useMeta ? 'Find freelance services from ' + this.username + ' - AnyTask.com' : undefined
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.useMeta ? this.image_path : undefined
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.useMeta ? this.descriptionContent.replace(/(<\/p><p>)/ig, ' ').replace(/(<([^>]+)>)/ig, '') : undefined
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: this.useMeta ? window.location.protocol + '//' + window.location.host + '/' + this.username : undefined
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: this.useMeta ? 'article' : undefined
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: this.useMeta ? config.anytasks_name : undefined
        }
      ]
    }
  },
  data () {
    return {
      customToolbar: [['bold', 'italic', 'underline']],
      descriptionContent: this.shortDescription ? this.shortDescription : 'Check out my AnyTask profile',
      description: this.$sanitize(this.shortDescription) === 'null' ? '' : this.$sanitize(this.shortDescription),
      avatar: null,
      saved: false,
      saving: false,
      changedValues: [],
      isOwnProfile: localStorage.getItem('username') === this.username,
      validation: null,
      MessageSwitch: true,
      route: window.location.protocol + '//' + window.location.host + '/' + this.username
    }
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false
        this.saveChangedValues()
      },
      deep: true
    },
    description: {
      handler: function () {
        if (this.shortDescription !== this.$sanitize(this.description)) {
          this.saveChangedValues()
        }
      }
    }
  },
  mounted () {
    helper.reorderMetas()
  },
  methods: {
    messageSwitchCheck () {
      if (this.isOwnProfile) {
        return false
      } else return this.admin || this.tasks
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    goToUser () {
      if (this.clickable) {
        this.$router.push('/' + this.username)
      }
    },
    saveChangedValues () {
      if (this.description !== this.shortDescription && this.avatar !== null) {
        this.changedValues.splice(0, 1)
        this.changedValues.push({ description: this.description, image: this.avatar.s3ObjectLocation })
      } else if (this.description !== this.shortDescription && this.avatar === null) {
        this.changedValues.splice(0, 1)
        this.changedValues.push({ description: this.description })
      } else if (this.avatar !== null) {
        this.changedValues.splice(0, 1)
        this.changedValues.push({ image: this.avatar.s3ObjectLocation })
      } else if (this.description === this.shortDescription && this.avatar === null) {
        this.changedValues = []
      }

      this.emitChanges()
      if (this.shortDescription !== this.description) {
        this.validation = this.description.length > 0
      } else {
        this.validation = null
      }
    },
    emitChanges () {
      this.$emit('updateProfile', this.changedValues)
    },
    report () {
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show('loginReport')
      } else {
        this.$bvModal.show(this.screen + this.hash)
      }
    },
    contact () {
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show('loginContact')
      } else {
        this.$bvModal.show('contactModal')
      }
    }
  }
}
</script>

<style scoped>
  .prewrap {
    white-space: pre-wrap;
  }

  .add {
    z-index: 1000;
    bottom: 12px;
    right: 14px;
  }

  .fa-circle-plus {
    background-color: #ffffff;
    color: #44aae1;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    display: block;
    font-size: 150%;
  }

  .fa-facebook-square {
    color: #3b5998;
    cursor: pointer;
  }

  .fa-linkedin {
    color: #0976b4;
    cursor: pointer;
  }

  .fa-twitter-square {
    color: #55acee;
    cursor: pointer;
  }

  .fa-whatsapp {
    color: #25d366;
    cursor: pointer;
  }

  .fa-reddit {
    color: #ff5700;
    cursor: pointer;
  }

  .edit-image {
    position: relative;
  }

  .profile-image img {
    width: 100%;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
  }

  .profile-avatar {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
  }

  .info {
    border-top: 1px solid #ddd;
  }

  .rating ul li {
    float: left;
    padding-left: 2px;
    color: #62646a !important;
  }

  .rating ul {
    display: inline-block;
  }

  .rating ul li i {
    color: #ffbf00;
  }

  ul {
    list-style-type: none;
    font-size: 14px;
  }

  .info ul li {
    padding-bottom: 10px;
    color: #62646a !important;
  }

  .info ul li:last-of-type {
    padding-bottom: 0;
  }

  .description {
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #62646a;
  }

  .description button {
    color: inherit;
    text-decoration: unset;
  }

  .box-title h2 {
    text-transform: none;
  }

  .fa-share-nodes {
    color: #62646a;
    cursor: pointer;
    margin-left: 12px;
    font-size: 1.2em;
    text-decoration: none !important;
  }

  .fa-share-nodes:hover {
    color: #44aae1;
    opacity: 1;
  }
</style>

<style>
  #user-summary-description p {
    margin: 0 !important;
    min-height: 1rem;
  }
</style>
