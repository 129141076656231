import store from '../store'
import Home from '../views/Home'
import Profile from '../views/Profile'
import TaskInfo from '../views/Task/TaskInfo'
import ApiService from '../apiService'
import config from '../config'

export default [
  {
    path: '/go/:influencer([\\w\\d\\.]+)',
    name: 'influencer',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "Admin" */ '../views/Admin/AdminHomePage'),
    meta: { authorize: ['ADMIN'] }
  }, {
    path: '/admin/reviews',
    name: 'admin_reviews',
    component: () => import(/* webpackChunkName: "AdminReviews" */ '../views/Admin/AdminReviews'),
    meta: { authorize: ['ADMIN_REVIEWS'] }
  },
  {
    path: '/admin/tasks',
    name: 'admin_tasks_home',
    component: () => import(/* webpackChunkName: "AdminTasks" */ '../views/Admin/Task/AdminTaskLandingPage'),
    meta: { authorize: ['ADMIN_TASK'] }
  },
  {
    path: '/admin/tasks/approve',
    name: 'admin_task',
    component: () => import(/* webpackChunkName: "AdminTasksApproval" */ '../views/Admin/Task/AdminTaskList'),
    meta: { authorize: ['ADMIN_TASK'] }
  },
  {
    path: '/admin/tasks/approve/:hash',
    name: 'admin_task_approve_with_hash',
    component: () => import(/* webpackChunkName: "AdminTasksApproval" */ '../views/Admin/Task/AdminTaskList'),
    meta: { authorize: ['ADMIN_TASK_SINGLE'] }
  },
  {
    path: '/admin/tasks/:status',
    name: 'admin_tasks',
    component: () => import(/* webpackChunkName: "AdminTasks" */ '../views/Admin/Task/AdminTaskLists'),
    meta: { authorize: ['ADMIN_TASK'] }
  },
  {
    path: '/admin/tasks/:username/:slug/edit',
    name: 'admin_task_edit',
    component: () => import(/* webpackChunkName: "AdminTasks" */ '../views/Admin/Task/AdminEditTask'),
    meta: { authorize: ['ADMIN_TASK_LIVE_EDIT'] }
  },
  {
    path: '/admin/users',
    name: 'admin_users',
    component: () => import(/* webpackChunkName: "AdminUser" */ '../views/Admin/User/AdminUsersLandingPage'),
    meta: { authorize: ['ADMIN_USER'] }
  },
  {
    path: '/admin/users/list/:status',
    name: 'admin_users_list',
    component: () => import(/* webpackChunkName: "AdminUser" */ '../views/Admin/User/AdminUsers'),
    meta: { authorize: ['ADMIN_USER'] }
  },
  {
    path: '/admin/users/:hash',
    name: 'admin_user',
    component: () => import(/* webpackChunkName: "AdminUser" */ '../views/Admin/User/AdminUserProfile'),
    meta: { authorize: ['ADMIN_USER'] }
  },
  {
    path: '/admin/dispute/:action',
    name: 'admin_disputes',
    component: () => import(/* webpackChunkName: "AdminDisputes" */ '../views/Admin/Dispute/AdminDisputes'),
    props: true,
    meta: { authorize: ['ADMIN_DISPUTE_'] }
  },
  {
    path: '/admin/dispute/:action/:hash',
    name: 'admin_dispute',
    component: () => import(/* webpackChunkName: "AdminDisputes" */ '../views/Admin/Dispute/AdminDispute'),
    meta: { authorize: ['ADMIN_DISPUTE_'] }
  },
  {
    path: '/admin/dispute',
    name: 'admin_dispute_landing_page',
    component: () => import(/* webpackChunkName: "AdminDisputes" */ '../views/Admin/Dispute/AdminDisputeLandingPage'),
    props: true,
    meta: { authorize: ['ADMIN_DISPUTE'] }
  },
  {
    path: '/admin/rewards',
    name: 'admin_rewards',
    component: () => import(/* webpackChunkName: "Rewards" */ '../views/Admin/Reward/AdminRewards'),
    props: true,
    meta: { authorize: ['ADMIN_REWARD'] }
  },
  {
    path: '/admin/rewards/:action/:hash',
    name: 'admin_rewards_hash',
    component: () => import(/* webpackChunkName: "Rewards" */ '../views/Admin/Reward/AdminReward'),
    props: true,
    meta: { authorize: ['ADMIN_REWARD'] }
  },
  {
    path: '/admin/featured',
    name: 'admin_featured',
    component: () => import(/* webpackChunkName: "AdminFeatured" */ '../views/Admin/AdminFeatured.vue'),
    meta: { authorize: ['ADMIN_FEATURED'] }
  },
  {
    path: '/admin/reports/:action',
    name: 'admin_reports_table',
    component: () => import(/* webpackChunkName: "AdminReports" */ '../views/Admin/Reports/AdminReports'),
    meta: { authorize: ['ADMIN_REPORT'] }
  },
  {
    path: '/admin/reports',
    name: 'admin_reports',
    component: () => import(/* webpackChunkName: "AdminReports" */ '../views/Admin/Reports/AdminReportsLandingPage'),
    meta: { authorize: ['ADMIN_REPORT'] }
  },
  {
    path: '/admin/orders',
    name: 'admin_orders',
    component: () => import(/* webpackChunkName: "AdminOrders" */ '../views/Admin/AdminOrders'),
    props: true,
    meta: { authorize: ['ADMIN_VIEW_ORDERS'] }
  },
  {
    path: '/admin/orders/:hash',
    name: 'admin_order',
    component: () => import(/* webpackChunkName: "AdminOrders" */ '../views/Task/ReceiveTask'),
    meta: { authorize: ['ADMIN_VIEW_ORDERS'] }
  },
  {
    path: '/admin/giftcard',
    name: 'admin_giftcard',
    component: () => import(/* webpackChunkName: "AdminGiftCards" */ '../views/Admin/AdminGiftCard'),
    props: true,
    meta: { authorize: ['ADMIN_GIFTCARDS'] }
  },
  {
    path: '/admin/payment',
    name: 'admin_payment',
    component: () => import(/* webpackChunkName: "AdminPayments" */ '../views/Admin/Payment/AdminPayment'),
    meta: { authorize: ['ADMIN_PAYMENT'] }
  },
  {
    path: '/admin/payment/pending',
    name: 'admin_payment_pending',
    component: () => import(/* webpackChunkName: "AdminPayments" */ '../views/Admin/Payment/AdminPaymentPending'),
    meta: { authorize: ['ADMIN_PAYMENT'] }
  },
  {
    path: '/admin/payment/processed',
    name: 'admin_payment_processed',
    component: () => import(/* webpackChunkName: "AdminPayments" */ '../views/Admin/Payment/AdminPaymentProcessed'),
    meta: { authorize: ['ADMIN_PAYMENT'] }
  },
  {
    path: '/admin/feedback/:type',
    name: 'admin_feedback_type',
    component: () => import(/* webpackChunkName: "AdminFeedback" */ '../views/Admin/Feedback/AdminFeedback'),
    props: true,
    meta: { authorize: ['ADMIN_FEEDBACK'] }
  },
  {
    path: '/admin/feedback',
    name: 'admin_feedback',
    component: () => import(/* webpackChunkName: "AdminFeedback" */ '../views/Admin/Feedback/AdminFeedbackLanding'),
    props: true,
    meta: { authorize: ['ADMIN_FEEDBACK'] }
  },
  {
    path: '/admin/accounting',
    name: 'admin_accounting',
    component: () => import(/* webpackChunkName: "AdminAccounting" */ '../views/Admin/Accounting/AdminAccountingLanding'),
    props: true,
    meta: { authorize: ['ADMIN_ACCOUNTING'] }
  },
  {
    path: '/admin/accounting/crypto',
    name: 'admin_accounting_crypto',
    component: () => import(/* webpackChunkName: "AdminAccounting" */ '../views/Admin/Accounting/AdminAccountingCrypto'),
    props: true,
    meta: { authorize: ['ADMIN_ACCOUNTING'] }
  },
  {
    path: '/admin/job',
    name: 'admin_job_landing',
    component: () => import(/* webpackChunkName: "AdminJobs" */ '../views/Admin/Job/AdminJobLandingPage'),
    props: true,
    meta: { authorize: ['ADMIN_JOB_BOARD'] }
  },
  {
    path: '/admin/job/list',
    name: 'admin_job_list',
    component: () => import(/* webpackChunkName: "AdminJobs" */ '../views/Admin/Job/AdminJobList'),
    props: true,
    meta: { authorize: ['ADMIN_JOB_BOARD'] }
  },
  {
    path: '/admin/job/monitor',
    name: 'admin_job_monitor',
    component: () => import(/* webpackChunkName: "AdminJobs" */ '../views/Admin/Job/AdminJobMonitor'),
    props: true,
    meta: { authorize: ['ADMIN_JOB_BOARD'] }
  },
  {
    path: '/admin/job/pending',
    name: 'admin_job_pending',
    component: () => import(/* webpackChunkName: "AdminJobs" */ '../views/Admin/Job/AdminJobPending'),
    props: true,
    meta: { authorize: ['ADMIN_JOB_BOARD'] }
  },
  {
    path: '/admin/job/pending/:hash',
    name: 'admin_job_pending_details',
    component: () => import(/* webpackChunkName: "AdminJobs" */ '../views/Admin/Job/AdminJobPendingDetail'),
    props: true,
    meta: { authorize: ['ADMIN_JOB_BOARD'] }
  },
  {
    path: '/503',
    name: '503',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/Errors/503'),
    meta: { categorise: false }
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/Errors/500'),
    meta: { categorise: false }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/Errors/404'),
    meta: { categorise: false }
  },
  {
    path: '/410',
    name: '410',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/Errors/410'),
    meta: { categorise: false }
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index.html',
    redirect: {
      name: 'home'
    }
  },
  {
    path: '/become-seller',
    name: 'BecomeSeller',
    component: () => import(/* webpackChunkName: "BecomeSeller" */ '../views/BecomeSeller')
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import(/* webpackChunkName: "Sitemap" */ '../views/Sitemap')
  },
  {
    path: '/toolkit',
    name: 'Toolkit',
    component: () => import(/* webpackChunkName: "Toolkit" */ '../views/Toolkit')
  },
  {
    path: '/how-payments-work',
    name: 'HowPaymentsWork',
    component: () => import(/* webpackChunkName: "HowPaymentsWork" */ '../views/HowPaymentsWork')
  },
  {
    path: '/what-is-anytask',
    name: 'WhatIsAnyTasks',
    component: () => import(/* webpackChunkName: "WhatIsAnyTasks" */ '../views/WhatIsAnyTasks')
  },
  {
    path: '/token/:token([\\w\\d]+)',
    name: 'confirm_token',
    component: () => import(/* webpackChunkName: "ConfirmToken" */ '../views/MyAccount/ConfirmToken')
  },
  {
    path: '/confirm-email/:token',
    name: 'confirm_email',
    component: () => import(/* webpackChunkName: "ConfirmEmail" */ '../views/MyAccount/ConfirmEmail')
  },
  {
    path: '/login/sso',
    name: 'login_sso',
    component: () => import(/* webpackChunkName: "SSO" */ '../views/MyAccount/Sso'),
    beforeEnter (to, from, next) {
      store.dispatch('fetchAccessToken')
      if (store.state.accessToken !== null && (typeof to.query['sig'] === 'undefined' || typeof to.query['sso'] === 'undefined')) {
        next('/my-account')
      }
      next()
    }
  },
  {
    path: '/category/:slug',
    name: 'category',
    component: () => import(/* webpackChunkName: "Category" */ '../views/Category.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "Search" */ '../views/Task/TaskResults'),
    beforeEnter (to, from, next) {
      window.sessionStorage.removeItem('task_category')
      next()
    }
  },
  {
    path: '/category/:slug/:child',
    name: 'search_category',
    component: () => import(/* webpackChunkName: "Category" */ '../views/Category/CategoryTaskResults')
  },
  {
    path: '/my-account',
    redirect: {
      name: 'accountProfile'
    }
  },
  {
    path: '/my-account/dashboard',
    name: 'accountDashboard',
    component: () => import(/* webpackChunkName: "DashboardProfile" */ '../views/MyAccount/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/profile',
    name: 'accountProfile',
    component: () => import(/* webpackChunkName: "DashboardProfile" */ '../views/MyAccount/Profile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/orders',
    redirect: {
      name: 'view_my_purchases'
    }
  },
  {
    path: '/my-account/purchases',
    name: 'view_my_purchases',
    component: () => import(/* webpackChunkName: "Orders" */ '../views/MyAccount/ViewMyPurchases'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/sales',
    name: 'view_my_sales',
    component: () => import(/* webpackChunkName: "Orders" */ '../views/MyAccount/ViewMySales'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/orders/receive/:hash',
    name: 'receive_task',
    component: () => import(/* webpackChunkName: "Orders" */ '../views/Task/ReceiveTask'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/orders/deliver/:hash([\\w\\d]+)',
    name: 'deliver_task',
    component: () => import(/* webpackChunkName: "Orders" */ '../views/Task/DeliverTask'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/tasks/saved',
    name: 'saved-tasks',
    component: () => import(/* webpackChunkName: "SavedTasks" */ '../views/MyAccount/SavedTasks'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/tasks/edit/:slug([A-Za-z0-9\\-\\_]+)',
    name: 'editTask',
    component: () => import(/* webpackChunkName: "MyTasks" */ '../views/Task/EditTask'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/tasks/create',
    name: 'createTask',
    component: () => import(/* webpackChunkName: "MyTasks" */ '../views/MyAccount/CreateTask'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/tasks',
    name: 'myTasks',
    component: () => import(/* webpackChunkName: "tasks" */ '../views/MyAccount/Tasks'),
    meta: {
      authorize: ['PRE_SELLER', 'SELLER']
    }
  },
  {
    path: '/my-account/inbox/:hash?',
    name: 'inbox',
    component: () => import(/* webpackChunkName: "Inbox" */ '../views/MyAccount/Inbox'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/my-account/transactions',
    name: 'transactions',
    component: () => import(/* webpackChunkName: "Transactions" */ '../views/MyAccount/ViewTransactions'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/billing',
    name: 'billing',
    component: () => import(/* webpackChunkName: "Billing" */ '../views/MyAccount/BillingDetails'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/limits',
    name: 'accountLimits',
    component: () => import(/* webpackChunkName: "AccountLimits" */ '../views/MyAccount/AccountLimits'),
    meta: {
      requiresAuth: true,
      requiresBand: true
    }
  },
  {
    path: '/my-account/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/MyAccount/Settings'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/connecting-etn',
    name: 'connecting-etn',
    component: () => import(/* webpackChunkName: "ConnectEtn" */ '../views/MyAccount/ConnectingEtn'),
    beforeEnter (to, from, next) {
      if (store.getters.hasRole('ROLE_PRE_SELLER')) {
        next()
      } else {
        next({ name: 'seller-welcome' })
      }
    },
    meta: {
      requiresAuth: true,
      categorise: false,
      unavailableToSeller: true,
      connectingEtnProcess: true
    }
  },
  {
    path: '/my-account/welcome',
    name: 'seller-welcome',
    component: () => import(/* webpackChunkName: "Welcome" */ '../views/MyAccount/SellerWelcome'),
    meta: {
      requiresAuth: true,
      unavailableToSeller: true,
      connectingEtnProcess: true
    }
  },
  {
    path: '/my-account/connect-app',
    name: 'connect-app',
    component: () => import(/* webpackChunkName: "ConnectApp" */ '../views/MyAccount/ConnectApp'),
    meta: {
      requiresAuth: true,
      categorise: false,
      unavailableToSeller: true,
      connectingEtnProcess: true
    }
  },
  {
    path: '/my-account/account-type',
    name: 'account-type',
    component: () => import(/* webpackChunkName: "AccountType" */ '../views/MyAccount/AccountType'),
    meta: {
      requiresAuth: true,
      categorise: false,
      unavailableToSeller: true
    }
  },
  {
    path: '/my-account/job',
    name: 'job-view',
    component: () => import(/* webpackChunkName: "AccountType" */ '../views/MyAccount/Job/ViewPage'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/job/create',
    name: 'job-create',
    component: () => import(/* webpackChunkName: "AccountType" */ '../views/MyAccount/Job/CreateEdit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my-account/job/edit/:hash',
    name: 'job-edit',
    component: () => import(/* webpackChunkName: "AccountType" */ '../views/MyAccount/Job/CreateEdit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkout/order-requirements',
    name: 'order-requirements',
    component: () => import(/* webpackChunkName: "Checkout" */ '../views/Checkout/CheckoutRequirements'),
    meta: {
      requiresAuth: true,
      categorise: false
    }
  },
  {
    path: '/checkout/confirmation',
    name: 'confirmation',
    props: true,
    component: () => import(/* webpackChunkName: "Checkout" */ '../views/Checkout/CheckoutConfirmation'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkout/:basket([\\w\\d]+)/pay/:queryID?',
    name: 'order_checkout_pay',
    component: () => import(/* webpackChunkName: "Checkout" */ '../views/Checkout/CheckoutOrders'),
    meta: {
      requiresAuth: true,
      categorise: false
    }
  },
  {
    path: '/checkout',
    name: 'order_details_no_hash',
    meta: {
      requiresAuth: true
    },
    beforeEnter (to, from, next) {
      ApiService.shoppingBasketGet().then(response => {
        if (config.tracking_enabled === 'true') {
          window.__at_px.fire('basket_add')
        }
        next('/checkout/' + response.data.data[0].hash + '/pay')
      }).catch(() => {
        next('/')
      })
    }
  },
  {
    path: '/user/forgot-password/:token',
    name: 'forgotten_password',
    component: () => import(/* webpackChunkName: "ForgottenPassword" */ '../views/MyAccount/ForgottenPassword'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyNotice')
  },
  {
    path: '/intellectual-property',
    name: 'intellectual_property',
    component: () => import(/* webpackChunkName: "IntellectualProperty" */ '../views/IntellectualProperty')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms_conditions',
    component: () => import(/* webpackChunkName: "TermsConditions" */ '../views/TermsAndConditions')
  },
  {
    path: '/anytask-protection',
    name: 'anytask_protection',
    component: () => import(/* webpackChunkName: "AnytaskProtection" */ '../views/AnyTaskProtection')
  },
  {
    path: '/meet-the-sellers',
    name: 'meet_the_sellers',
    component: () => import(/* webpackChunkName: "MeetTheSellers" */ '../views/MeetTheSeller/MeetTheSellersSelection')
  },
  {
    path: '/meet-the-sellers/:sellerId',
    name: 'meet_the_sellers_article',
    component: () => import(/* webpackChunkName: "MeetTheSellers" */ '../views/MeetTheSeller/MeetTheSellersArticle')
  },
  {
    path: '/writing-your-brief',
    name: 'writing_your_brief',
    component: () => import(/* webpackChunkName: "WritingYourBrief" */ '../views/WritingYourBrief')
  },
  {
    path: '/job-board',
    name: 'job_board',
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/JobBoard')
  },
  {
    path: '/jobs/:hash([\\w\\d]+)',
    name: 'jobs_view',
    component: () => import(/* webpackChunkName: "Jobs" */ '../views/Job/Details'),
    beforeEnter (to, from, next) {
      to.meta.back = from.name === 'job-view' ? from.name : 'job_board'
      next()
    }
  },
  {
    path: '/:username([\\w\\d\\.]+)/:slug([A-Za-z0-9\\-\\_]+)/:queryID?',
    name: 'task_info',
    props: true,
    component: TaskInfo,
    beforeEnter (to, from, next) {
      if (from.name === 'search_category') {
        window.sessionStorage.setItem('task_category', from.path.replace('/category/', ''))
      }
      next()
    }
  },
  {
    path: '/:username([\\w\\d\\.]+)',
    name: 'profile',
    component: Profile
  },
  {
    path: '/(.*)',
    name: '404_catch_all',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/Errors/404'),
    meta: { categorise: false }
  }
]
