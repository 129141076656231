<template>
  <div class="d-flex flex-column flex-lg-row">
    <div class="d-lg-none d-flex justify-content-end my-3">
      <div @click="toggleMobileFiltersOpen()" class="p-1">
        <i class="fa-solid fa-xmark fa-xl text-secondary"></i>
      </div>
    </div>
    <ais-refinement-list
      class="py-1 px-1 filter text-left"
      attribute="user.languages"
      searchable
    >
      <template
        v-slot="{
        items,
        isFromSearch,
        refine,
        createURL,
        searchForItems,
        sendEvent,
      }"
      >
        <b-dropdown no-flip size="lg" class="rounded-pill seller-language border-0" :variant="items.findIndex(item => item.isRefined) >=0 ? 'primary' : 'link'" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <div :class="items.findIndex(item => item.isRefined) >= 0 ? 'white-text' : 'text-dark'" class="d-flex align-items-center">
              <h5 class="mb-0 text-left">{{ $t('sidebar_subheader_seller_language') }}</h5>
              <i class="fa-solid fa-chevron-down d-none d-lg-block fa-2xs ml-2"></i>
            </div>
          </template>
          <div class="rounded-pill input-container position-relative my-2 bg-gray px-3 py-2 w-100 d-flex align-items-center">
            <i class="fa-solid fa-magnifying-glass fa-sm text-secondary mx-2"></i>
            <input class="border lang-input flex-grow-1 border-0 bg-transparent"
                   @input="searchForItems($event.currentTarget.value)"
                   :placeholder="$t('start_typing_country')"
            >
          </div>
          <div class="sub-dropdown">
            <b-dropdown-item class="w-100" v-if="isFromSearch && !items.length">{{ $t('filter_language_no_results') }}</b-dropdown-item>
            <b-dropdown-item v-for="(item) in items"
                             :key="item.value"
                             :href="createURL(item.value)"
                             @click.prevent="refine(item.value), item.isRefined ? removeActiveFilter(`language_${item.value}`) : addActiveFilter(`language_${item.value}`)"
                             class="d-flex align-items-center"
            >
              <div class="d-flex align-items-center">
                <i v-if="item.isRefined" class="fa-solid fa-square-check fa-lg mr-4"></i>
                <i v-else class="fa-regular fa-square fa-lg mr-4"></i>
                {{ item.label }}
              </div>
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>
    </ais-refinement-list>
    <ais-numeric-menu
      class="py-1 px-1 filter"
      attribute="packages.price"
      :items="[
                { label: this.$t('filter_option_all') },
                { label: this.$t('filter_price_under') + ' $5', end: 5 },
                { label: '$5 - $10', start: 5, end: 10 },
                { label: '$10 - $25', start: 10, end: 25 },
                { label: '$25 - $50', start: 25, end: 50 },
                { label: '$50+', start: 50 },
              ]"
    >
      <template v-slot="{ items, refine, createURL, sendEvent }">
        <b-dropdown no-flip size="lg" class="rounded-pill" :variant="items.findIndex(item => item.isRefined) > 0 ? 'primary' : 'link'" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <div :class="items.findIndex(item => item.isRefined) > 0 ? 'white-text' : 'text-dark'" class="d-flex align-items-center">
              <h5 class="mb-0 text-left">{{ $t('sidebar_subheader_price_range') }}</h5>
              <i class="fa-solid fa-chevron-down d-none d-lg-block fa-2xs ml-2"></i>
            </div>
          </template>
          <b-dropdown-item v-for="(item, index) in items"
                           :key="item.value"
                           :href="createURL(item.value)"
                           @click.prevent="refine(item.value), index > 0 ? addActiveFilter('price') : removeActiveFilter('price')"
                           class="d-flex align-items-center"
          >
            <div class="d-flex align-items-center">
              <i v-if="item.isRefined" class="fa-solid fa-square-check fa-lg mr-4"></i>
              <i v-else class="fa-regular fa-square fa-lg mr-4"></i>
              {{ item.label }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </ais-numeric-menu>
    <ais-numeric-menu
      class="py-1 px-1 filter"
      attribute="packages.delivery"
      :items="[
                { label: this.$t('filter_delivery_option_any') },
                { label: this.$t('filter_delivery_option_24_hours'), end: 24 },
                { label: this.$t('filter_delivery_option_3_days'), end: 72 },
                { label: this.$t('filter_delivery_option_7_days'), end: 168 }
              ]"
    >
      <template v-slot="{ items, refine, createURL, sendEvent }">
        <b-dropdown no-flip size="lg" class="rounded-pill" :variant="items.findIndex(item => item.isRefined) > 0 ? 'primary' : 'link'" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <div :class="items.findIndex(item => item.isRefined) > 0 ? 'white-text' : 'text-dark'" class="d-flex align-items-center">
              <h5 class="mb-0 text-left">{{ ($t('sidebar_subheader_delivery_time')) }}</h5>
              <i class="fa-solid fa-chevron-down d-none d-lg-block fa-2xs ml-2"></i>
            </div>
          </template>
          <b-dropdown-item v-for="(item, index) in items"
                           :key="item.value"
                           :href="createURL(item.value)"
                           @click.prevent="refine(item.value), index > 0 ? addActiveFilter('delivery') : removeActiveFilter('delivery')"
                           class="d-flex align-items-center"
          >
            <div class="d-flex align-items-center">
              <i v-if="item.isRefined" class="fa-solid fa-square-check fa-lg mr-4"></i>
              <i v-else class="fa-regular fa-square fa-lg mr-4"></i>
              {{ item.label }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </ais-numeric-menu>
    <ais-numeric-menu
      class="py-1 px-1 filter"
      attribute="review_average"
      :items="[
                { label: this.$t('filter_option_all') },
                { label: 1, start: 1, end: 5 },
                { label: 2, start: 2, end: 5 },
                { label: 3, start: 3, end: 5 },
                { label: 4, start: 4, end: 5 },
                { label: 5, start: 5 }
              ]"
    >
      <template v-slot="{ items, refine, createURL, sendEvent }">
        <b-dropdown no-flip size="lg" class="rounded-pill" :variant="items.findIndex(item => item.isRefined) > 0 ? 'primary' : 'link'" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <div :class="items.findIndex(item => item.isRefined) > 0 ? 'white-text' : 'text-dark'" class="d-flex align-items-center">
              <h5 class="mb-0 text-left">{{ $t('rating_title') }}</h5>
              <i class="fa-solid fa-chevron-down d-none d-lg-block fa-2xs ml-2"></i>
            </div>
          </template>
          <b-dropdown-item v-for="(item, index) in items"
                           :key="item.value"
                           :href="createURL(item.value)"
                           @click.prevent="refine(item.value), index > 0 ? addActiveFilter('reviews') : removeActiveFilter('reviews')"
                           class="d-flex align-items-center"
          >
            <div class="d-flex align-items-center">
              <i v-if="item.isRefined" class="fa-solid fa-square-check fa-lg mr-4"></i>
              <i v-else class="fa-regular fa-square fa-lg mr-4"></i>
              <span class="text-left mr-2" v-if="item.label !== 'All'">
                <i class="fa-solid star-yellow fa-star fa-xs"></i>
                <i class="star-yellow fa-star fa-xs" :class="[item.label <= 1 ? 'fa-regular' : 'fa-solid']"></i>
                <i class="star-yellow fa-star fa-xs" :class="[item.label <= 2 ? 'fa-regular' : 'fa-solid']"></i>
                <i class="star-yellow fa-star fa-xs" :class="[item.label <= 3 ? 'fa-regular' : 'fa-solid']"></i>
                <i class="star-yellow fa-star fa-xs" :class="[item.label <= 4 ? 'fa-regular' : 'fa-solid']"></i>
              </span>
              {{ item.label }}
              <span v-if="item.label < 5">+ </span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </ais-numeric-menu>
    <ais-sort-by
      class="d-none d-lg-block py-1 px-1 filter text-left ml-auto"
      :items="[
                        { value: config.advanced_search.index_name, label: $t('tasks_sort_relevance')},
                        { value: config.advanced_search.index_name + '_price_asc', label: $t('tasks_sort_lowest_price')},
                        { value: config.advanced_search.index_name + '_price_desc', label: $t('tasks_sort_highest_price')},
                        { value: config.advanced_search.index_name + '_reviews_desc', label: $t('tasks_sort_best_reviewed')},
                        { value: config.advanced_search.index_name + '_last_active_desc', label: $t('tasks_sort_last_active')}
                      ]"
    >
      <template v-slot="{ items, currentRefinement, refine }">
        <b-dropdown no-flip size="lg" class="rounded-pill" :variant="items.findIndex(item => item.isRefined) > 0 ? 'primary' : 'link'" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <div :class="items.findIndex(item => item.isRefined) > 0 ? 'white-text' : 'text-dark'" class="d-flex align-items-center">
              <h5 class="mb-0 text-left">{{ $t('tasks_sort_by') }} {{ getCurrentSortBy(items, currentRefinement) }}</h5>
              <i class="fa-solid fa-chevron-down d-none d-lg-block fa-2xs ml-2"></i>
            </div>
          </template>
          <b-dropdown-item v-for="(item) in items"
                           :key="item.value"
                           href="#"
                           @click.prevent="refine(item.value)"
                           class="d-flex align-items-center"
          >
            <div class="d-flex align-items-center">
              <i v-if="item.label === getCurrentSortBy(items, currentRefinement)" class="fa-solid fa-square-check fa-lg mr-4"></i>
              <i v-else class="fa-regular fa-square fa-lg mr-4"></i>
              {{ item.label }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </ais-sort-by>
    <div class="spacer d-lg-none order-10"></div>
    <div class="position-fixed fixed-bottom w-100 bg-white d-flex p-3 d-lg-none buttons">
      <ais-clear-refinements class="w-100">
        <template v-slot="{ canRefine, refine, createURL }">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button
              variant="secondary"
              class="btn-md rounded-pill w-50"
              :href="createURL()"
              @click.prevent="refine(), clearActiveFilters()"
              v-if="canRefine"
            >
              {{ $t('clear_all') }}
            </b-button>
            <b-button variant="outline-secondary" disabled class="btn-md rounded-pill w-50" v-else>
              {{ $t('clear_all') }}
            </b-button>
            <b-button class="btn-md rounded-pill w-50 ml-2" @click="toggleMobileFiltersOpen()" variant="primary">{{ $t('filter_apply') }}</b-button>
          </div>
        </template>
      </ais-clear-refinements>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import config from '../../config'

export default {
  name: 'AdvancedSearchFilters',
  methods: {
    ...mapActions(['setFiltersOpen', 'addActiveFilter', 'removeActiveFilter', 'clearActiveFilters']),
    getCurrentSortBy (items, currentRefinement) {
      const currentItem = items.filter(item => item.value === currentRefinement)
      return currentItem[0].label
    },
    toggleMobileFiltersOpen () {
      this.setFiltersOpen(!this.filtersOpen)
      window.scrollTo(0, 0)
    }
  },
  computed: {
    ...mapState({ filtersOpen: state => state.search.filtersOpen }),
    config () {
      return config
    }
  }
}
</script>

<style lang="scss">

.filter, .mobile-sort-by {
  .dropdown-toggle {
    border-radius: 50px !important;
    padding-block: 0.3em !important;
    margin-block: 2px;
    &:hover {
      border: 1px transparent solid !important;
    }

    .white-text {
      color: white;
    }

    h5 {
      font-size: 1.15rem;
      font-weight: normal;
    }
  }

  .seller-language {
    .input-container {
      max-width: 400px;
      input {
        &:focus-visible {
          outline: none !important;
        }
      }
    }

    >.dropdown-menu {
      min-width: 300px;
      .sub-dropdown {
        overflow-y: scroll;
        max-height: 350px;
      }
    }
  }

  .dropdown-menu {
    background-color: white;
    padding: 8px 8px!important;
    border: none;
    margin-top: 10px;
    border-radius: 10px !important;
    z-index: 10;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    right:0;
    min-width: 200px;
    max-height: 420px;
    background-clip: unset;

    li {
      padding: 8px 8px !important;
      border-bottom: 2px #F5F5F5 solid !important;

      &:hover {
        background-color: #EEEEEE !important;
      }
      .dropdown-item {
        font-size: 0.9rem;

        &:active {
          color: unset;
        }

        &:hover {
          background-color: unset !important;
        }
      }

      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}

@media (max-width: 991px) {

  .filter {
    background-color: white;
    border-bottom: 2px #F5F5F5 solid;
    .dropdown {
    display: flex;
    flex-direction: column;

    .dropdown-toggle {
      background-color: transparent !important;
      color: $brand-denim !important;
      border: 1px transparent solid !important;

      .white-text {
        color: unset;
      }
    }
      .sub-dropdown {
        max-height: 250px !important;
        border: 1px #F5F5F5 solid;
        border-radius: 5px;
        margin-block: 20px;
      }

    .dropdown-menu {
      position: unset !important;
      display: block !important;
      margin-top: 0;
      transform: unset !important;
      -webkit-box-shadow: unset;
      box-shadow: unset;
      max-height: unset;
      min-width: unset;
      li {
        padding: 15px 30px !important;
        .dropdown-item:hover {
            background-color: unset !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../assets/CSS/_variables.scss';

.star-yellow {
  color: $star-yellow;
}

.spacer {
  width: 10px;
  height: 10vh;
}

.buttons {
  -webkit-box-shadow: 0 -4px 8px 0 rgba(0,0,0,.075);
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,.075);
}
</style>
