import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import apiService from './apiService'
import tracking from './store/Modules/tracking'
import currency from './store/Modules/currency'
import payments from './store/Modules/payments'
import messages from './store/Modules/messages'
import login from './store/Modules/login'
import register from './store/Modules/register'
import search from './store/Modules/search'
import user from './store/Modules/user'
import settings from './store/Modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tracking,
    currency,
    payments,
    messages,
    user,
    login,
    register,
    search,
    settings
  },
  state: {
    accessToken: localStorage.getItem('accessToken'),
    pageNotFound: false,
    emailConfirmTokenSuccessful: null,
    refreshToken: null,
    profileIncomplete: null,
    local: 'en',
    termsRequired: false
  },
  mutations: {
    signOut: (state) => {
      state.accessToken = null
      state.refreshToken = null
      state.profileIncomplete = null
      state.termsAccepted = false
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken
    },
    updateRefreshToken: (state, refreshToken) => {
      state.refreshToken = refreshToken
    },
    updateProfileIncomplete: (state, status) => {
      state.profileIncomplete = status
    },
    setTermsRequired: (state, data) => {
      state.termsRequired = data
    }
  },
  actions: {
    fetchAccessToken ({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'))
    },
    fetchNewAccessToken ({ commit, dispatch }) {
      return apiService.loginRefresh(localStorage.getItem('refreshToken'))
        .then(response => {
          localStorage.setItem('accessToken', response.data.token)
          localStorage.setItem('refreshToken', response.data.refresh_token)
          commit('updateAccessToken', response.data.token)
          commit('updateRefreshToken', response.data.refresh_token)
          dispatch('processJwt')
        }).catch(() => { })
    },
    fetchRefreshToken ({ commit }) {
      commit('updateRefreshToken', localStorage.getItem('refreshToken'))
    },
    signOut ({ commit }) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('username')
      localStorage.removeItem('profile_picture')
      localStorage.removeItem('profile_incomplete')
      localStorage.removeItem('role_seller')
      localStorage.removeItem('orderRequirements')
      localStorage.removeItem('signUpType')
      commit('resetUserState', {})
      commit('setGiftCard', [])
      commit('signOut')
      router.push({ name: 'home' })
    },
    removeProfileIncomplete ({ commit }) {
      commit('updateProfileIncomplete', null)
    },
    updateRefreshToken ({ commit }, data) {
      commit('updateRefreshToken', data)
    },
    parseJwt () {
      let token = localStorage.getItem('accessToken')
      if (token) {
        let base64Url = token.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))

        return JSON.parse(jsonPayload)
      } else {
        return null
      }
    },
    processJwt ({ dispatch, commit }) {
      dispatch('parseJwt').then(function (resp) {
        localStorage.setItem('username', resp.profile.username)
        if (localStorage.getItem('profile_picture') === null) {
          localStorage.setItem('profile_picture', resp.profile.photo_path)
        }
        if (resp.roles.includes('ROLE_SELLER')) {
          localStorage.setItem('role_seller', resp.roles.includes('ROLE_SELLER'))
        } else {
          localStorage.removeItem('role_seller')
        }
        if (resp.profile.profile_incomplete) {
          localStorage.setItem('profile_incomplete', resp.profile.profile_incomplete)
        } else {
          localStorage.removeItem('profile_incomplete')
        }
        if (localStorage.getItem('user-currency') !== null && localStorage.getItem('user-currency') !== resp.profile.currency) {
          apiService.patchUser({ 'currency': localStorage.getItem('user-currency') }).then(response => {
            localStorage.setItem('accessToken', response.data.data.token)
            localStorage.setItem('refreshToken', response.data.data.refresh_token)
            commit('updateAccessToken', response.data.data.token)
            commit('updateRefreshToken', response.data.data.refresh_token)
            dispatch('processJwt')
          }).catch(() => { })
        } else {
          commit('setTermsRequired', resp.terms_required)
          commit('buildUser', {
            username: resp.profile.username,
            avatar: resp.profile.photo_path,
            roles: resp.roles,
            hash: resp.hash,
            currency: resp.profile.currency,
            marketing: resp.profile.marketing,
            verification: resp.profile.verification,
            band: resp.profile.band,
            emailConfirmed: resp.profile.email_confirmed
          })
        }
      })
    }
  },
  getters: {}
})
