<template>
  <div :class="{ 'h-100': !profilePage }">
    <div class="card w-100" v-bind:class="{ 'opacity': selected === false & saveTasksPage, 'profilePage': profilePage, 'h-100': !profilePage }">
      <router-link :to="{name:'task_info', params:{username: username, slug:slug, queryID: queryID}}" target="_blank" rel="noopener" v-on:click.native="googleTagClickEvent()" class="h-100">
        <!-- card body -->
        <div class="body d-flex flex-row flex-md-column h-100 w-100" :class="{ 'featured-task': isFeaturedTask }">

          <!-- assets -->
          <div class="assets">
            <!-- save icons -->
            <div v-if="!profilePage && !tasksWidget" class="actions position-absolute" :class="{ 'featured-task': isFeaturedTask }">
              <span v-if="!saveTasksPage" class="fa-solid fa-heart cursor" v-bind:class="{ 'save-toggle' : selected }" v-b-popover.hover.top="popUpText" v-on:click="toggle"/>
              <span v-else class="fa-solid cursor" v-bind:class="{ 'fa-xmark' : selected }" v-b-popover.hover.top="popUpText" v-on:click="toggle"/>
            </div>
            <!-- assets carousel-->
            <Carousel class="w-100" :images="getImagesFromAssets(carouselImage)" :taskHash="hash" :seller="username"/>
          </div>

          <!-- Task Content -->
          <div class="content px-1 px-sm-2 pb-1 pb-sm-2 pt-1 d-flex flex-grow-1 flex-column flex-wrap" :class="[isFeaturedTask ? 'px-2' : 'order-1']">
            <!-- description M-1st D-3rd -->
            <div class="task-description d-flex flex-column align-items-start order-sm-3" :class="[isFeaturedTask ? 'order-3' : 'order-1']">
              <div class="mt-0 mt-sm-2 d-flex text-left copy" :class="tasksWidget ? 'align-items-start' : 'align-items-center'">
                <p class="m-0">{{ description }}</p>
              </div>
            </div>

            <!-- username/avatar M-2nd D-1st -->
            <div v-if="!tasksWidget" class="user d-flex flex-row w-100 align-items-center mb-0 order-sm-1" :class="[profilePage ? 'profilePage' : '', isFeaturedTask ? 'order-1' : 'order-2']">
              <!-- avatar -->
              <div class="avatar d-none d-sm-flex" :class="isFeaturedTask ? 'd-flex' : 'd-none'">
                <img v-if="imageIcon" :data-src="formatImageIcon(imageIcon)" :src="formatImageIcon(imageIcon)" alt="" class="user-icon test">
                <div v-if="!imageIcon" class="fa-stack icon-placeholder d-flex justify-content-center align-items-center" style="z-index: 1">
                  <i class="fa-solid fa-user text-light"/>
                </div>
              </div>
              <!-- username -->
              <div class="m-0 mx-sm-1 d-flex flex-column align-items-start">
                <router-link :to="{name:'profile', params:{username: username}}" class="username font-weight-bold text-left" :class="{'no-link': onDashboard}" target="_blank"
                             rel="noopener">
                  {{ username }}
                </router-link>
              </div>
            </div>

            <!-- labels/reviews M-3rd D-2nd -->
            <div class="labels d-flex align-items-center order-sm-2 mt-sm-2 flex-wrap" :class="[isFeaturedTask ? 'order-2' : 'order-3', isFeaturedTask ? 'mt-2' : 'mt-0']">
              <!--labels -->
              <span v-if="user_online" class="user-online badge badge-success mr-2">{{ $t('online') }}</span>
              <objectLabel v-if="isHeroTask" type="hero" :object="{}" class="mr-2 overflow-hidden"/>
              <ObjectLabel type="new"
                           :object="{'review_average': this.rating, 'review_count': this.count, 'user': { 'review_average': this.user_rating, 'review_count': this.user_count }}"></ObjectLabel>
            </div>
          </div>

          <!-- Reveiws & Delivery/Price -->
          <div class="delivery-price d-flex flex-row justify-content-between align-items-center pt-2 px-2 w-100 order-2" :class="[profilePage ? 'pb-0' : 'pb-2']">
            <!-- reviews -->
            <div class="reviews text-left d-flex align-items-center justify-content-start" v-if="count > 0">
              <div class="fa-solid fa-star checked"></div>
              <div class="rate font-weight-bold p-0">{{ rating.toFixed(1) }}&nbsp;({{ reviewLength() }})</div>
            </div>

            <!-- delivery/pricing -->
            <div class="d-flex flex-row text-right justify-content-end ml-auto">
              <!-- delivery -->

              <div class="delivery d-flex justify-content-start align-items-center font-weight-bold mr-1">
                <img alt="Delivery Icon" class="mr-1 delivery-icon" v-lazy="require('@/assets/Icons/delivery-icon.svg')" width="16" height="16">
                {{ deliveryTime / 24 }} {{ deliveryTime === 24 ? $t('delivery_time_day') : $t('delivery_time_days') }} /
              </div>
              <!-- pricing -->
              <div class="d-flex price flex-column font-weight-bold text-right" v-if="!requirementsPage">
                <span class="task-price">
                  {{ getDisplayPrice() }}
                </span>
              </div>
              <div v-else>
                <span class="card-price float-right task-price mb-0">{{ getDisplayPrice() }}</span>
              </div>
            </div>

          </div>
          <div v-if="profilePage || tasksWidget" class="d-flex justify-content-end w-100 order-3 px-2 pb-2">
            <div class="text-dark font-weight-normal etn-price">{{ $t('estimated') }} {{ etn }} ETN</div>
          </div>

          <!-- login for saving tasks -->
          <LoginModal v-if="uniqueId !== ''" :reason="$t('login_reason_favourite')" :id="uniqueId + 'loginFavourite'"/>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import LoginModal from '../Global/Login'
import Carousel from '../Global/CardCarousel'
import apiService from '../../apiService.js'
import ObjectLabel from '../Global/Label'
import helper from '../../helper'
import config from '../../config'
import { mapActions } from 'vuex'

export default {
  name: 'Card',
  components: { Carousel, LoginModal, ObjectLabel },
  props: {
    imageIcon: String,
    username: String,
    description: String,
    rating: Number,
    count: Number,
    user_rating: Number,
    user_count: Number,
    user_online: Boolean,
    isHeroTask: Boolean,
    price: Number,
    carouselImage: Object,
    taskImage: String,
    deliveryTime: Number,
    isFeaturedTask: Boolean,
    queryID: {
      type: String,
      required: false
    },
    select: {
      type: Boolean,
      required: false,
      default: false
    },
    imageSizeType: {
      type: Number,
      required: false,
      default () {
        return 2
      }
    },
    index: {
      type: [Number, String],
      required: false,
      default: null
    },
    etn: {
      type: String,
      required: false,
      default: null
    },
    slug: String,
    hash: String,
    saveTasksPage: {
      type: Boolean,
      required: false,
      default: false
    },
    profilePage: {
      type: Boolean,
      required: false,
      default: false
    },
    requirementsPage: {
      type: Boolean,
      required: false,
      default: false
    },
    tasksWidget: {
      type: Boolean,
      required: false,
      default: false
    },
    sellerInfo: Object,
    position: Number,
    location: String,
    primaryCategorySlug: {
      type: String,
      required: false,
      default: null
    },
    triggerTrackingEvent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    this.textToggle()
    this.uniqueId = this._uid
    this.localUsername = localStorage.getItem('username')
    this.googleTagOnLoad()
  },
  data () {
    return {
      selected: this.select,
      popUpText: '',
      uniqueId: '',
      localUsername: '',
      onDashboard: this.$route.name === 'myTasks',
      window: {
        width: 0,
        height: 0
      },
      assetsCounter: [
        0,
        0
      ],
      placeholderImage: 'https://images.anytask.com/image/upload/c_scale,h_220,w_330/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png'
    }
  },
  methods: {
    ...mapActions([
      'addFavourite',
      'removeFavourite'
    ]),
    googleTagOnLoad () {
      if (config.google_tag_manager !== '' && !this.triggerTrackingEvent && config.tracking_enabled === 'true') {
        let vm = this
        this.carouselImage.assets.forEach(function (item) {
          vm.assetsCounter[(item.type - 1)]++
        })
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'eec.impressionView',
          'ecommerce': {
            'impressions': [{
              'id': this.hash,
              'name': this.description,
              'category': this.primaryCategorySlug,
              'list': this.location,
              'brand': this.sellerInfo.hash,
              'position': this.position,
              'dimension5': this.sellerInfo.country_iso,
              'dimension6': this.sellerInfo.member_since,
              'metric2': this.assetsCounter[0],
              'metric3': this.assetsCounter[1],
              'metric4': this.count,
              'metric5': this.rating
            }]
          }
        })
      }
    },
    googleTagClickEvent () {
      if (config.google_tag_manager !== '' && !this.triggerTrackingEvent && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'eec.impressionClick',
          'ecommerce': {
            'click': {
              'actionField': {
                'list': this.location
              },
              'products': [{
                'id': this.hash,
                'name': this.description,
                'category': this.primaryCategorySlug,
                'brand': this.sellerInfo.hash,
                'position': this.position,
                'dimension5': this.sellerInfo.country_iso,
                'dimension6': this.sellerInfo.member_since,
                'metric2': this.assetsCounter[0],
                'metric3': this.assetsCounter[1],
                'metric4': this.count,
                'metric5': this.rating
              }]
            }
          }
        })
      }
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    changeSelected (value) {
      this.selected = value
    },
    getImagesFromAssets (media) {
      if (media.assets.length === 0) {
        return [{
          'type': 1,
          'src': this.placeholderImage
        }]
      }
      let images = []
      media.assets.forEach(function (asset) {
        let img = {}
        img['type'] = asset.type

        if (asset.type === 2) {
          // Movie
          if (this.imageSizeType === 2) {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie_large')
          } else {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie')
          }

          img['mp4'] = helper.formatCloudinaryUrl(asset.path, 'movie_mp4')
        } else {
          // Image
          if (this.imageSizeType === 2) {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'task_large')
          } else {
            img['src'] = helper.formatCloudinaryUrl(asset.path, 'task')
          }
        }
        images.push(img)
      }.bind(this.imageSizeType))
      return images
    },
    toggle (event) {
      event.stopPropagation()
      event.preventDefault()
      if (this.$store.state.accessToken === null) {
        this.$bvModal.show(this.uniqueId + 'loginFavourite')
      } else {
        if (this.selected) {
          this.$swal({
            title: this.$t('remove_task_title'),
            text: this.$t('remove_task_text'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('remove_task_confirm'),
            cancelButtonText: this.$t('remove_task_cancel')
          }).then((result) => {
            if (result.value === true) {
              this.selected = false
              this.textToggle()
              return apiService.removeFavouriteTask(this.hash).then(() => {
                this.removeFavourite(this.hash)
                this.$emit('removedSavedTask', this.index)
              }).catch(() => {
                this.selected = true
                this.textToggle()
                this.$swal(this.$t('remove_task_error_title'), this.$t('remove_task_error_text'), 'error')
              })
            }
          })
        } else {
          this.selected = true
          this.textToggle()
          apiService.addFavouriteTask(this.hash).then(() => {
            this.addFavourite(this.hash)
          }).catch(() => {
            this.selected = false
            this.textToggle()
            this.$swal(this.$t('remove_task_error_title'), this.$t('remove_task_error_text'), 'error')
          })
        }
      }
    },
    textToggle () {
      if (this.selected) {
        this.popUpText = this.$t('favourite_task_unselect')
      } else {
        this.popUpText = this.$t('favourite_task_select')
      }
    },
    reviewLength () {
      const num = this.count
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    },
    getDisplayPrice (currency) {
      return helper.getDisplayPrice(this.price, currency)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: .5rem !important;
  overflow: hidden;

  &.profilePage {
    border-radius: 0 !important;
  }

  .body {
    &.featured-task {
      .assets {
        width: 100%;
      }

      .content {
        width: 100%;
        min-height: 128px;
      }

      .task-description, .copy {
        min-height: 48px;
      }
    }

    .assets {
      width: 50%;
    }

    .content {
      width: 50%;
    }
  }

  a {
    color: $brand-navy;
    transition: .2s all;

    &:hover {
      transition: .2s all;
      text-decoration: none;
    }
  }

  .content {
    z-index: 1;

    .user {
      &.profilePage {
        display: none !important;
      }
    }

    .avatar {
      margin-top: -28px;

      img, .fa-stack {
        border: 2px solid white;
        width: 62px;
        height: 62px;
        border-radius: 50%;
      }

      .fa-stack {
        background: $brand-navy;
        font-size: 22px;
      }
    }
  }

  .actions {
    &.featured-task {
      right: 8px;
      left: auto;
    }

    top: 6px;
    left: 8px;
    z-index: 1;

    .fa-heart {
      color: white;
      opacity: .75;
      transition: all .2s;
      text-shadow: $card-action-shadow;

      &:hover {
        color: $brand-scarlet;
        transition: all .2s;
      }

      &.save-toggle {
        color: $brand-scarlet;
        opacity: 1;
      }
    }

    span {
      font-size: 1.5rem;
    }

    .fa-xmark {
      font-size: 1.25rem;
      color: white;
      text-shadow: $card-action-shadow;

      &:hover {
        color: $brand-sky;
      }
    }
  }

  .delivery {
    .delivery-icon {
      width: 16px;
    }
  }

  .reviews {
    height: 14px;

    .rate {
      font-size: 14px;
      width: 60px;
    }

    .checked {
      margin-right: 3px;
      color: $star-yellow;
      font-size: 14px;
    }
  }

  .task-description {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 22px;
    }

    &.featured-task {
      .copy {
        min-height: 48px;
      }
    }
  }

  .delivery-price {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: $card-review-area-shadow;
    background: #fefefe;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    flex-wrap: nowrap;
    font-size: 14px;
  }

  .etn-price {
    font-size: 10px;
  }

  .user-online.badge {
    position: relative;
  }

  .username {
    color: $light-grey;
  }
}

@include mobile-mid-up {
  .card {
    .labels {
      display: flex !important;
    }
  }
}

@include sm-up {
  .card {
    .body {
      .content {
        min-height: 128px;
      }

      .assets {
        width: 100%;
      }

      .content {
        width: 100%;

        .user {
          &.profilePage {
            display: flex !important;
          }
        }
      }
    }

    .actions {
      right: 8px;
      left: auto;
    }

    .task-description, .copy {
      min-height: 10px;
    }

    .mobile-labels {
      display: none !important;
    }
  }
}

@include md-up {
  .card {
    .task-description {
      p {
        line-height: 24px;
      }
    }
  }
}

@include md-down {
  .b-popover {
    display: none !important;
  }
}

</style>
