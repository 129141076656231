<template>
  <div class="bg-white pb-5">
    <!-- Headline -->
    <b-container>
      <div v-if="confirmEmailBanner !== null" class="confirm-email-banners-container">
        <!-- Confirm Email Success -->
        <div v-if="confirmEmailBanner" class="confirm-email-success-banner-container pt-3">
          <b-alert variant="success" dismissible fade show>{{ $t('confirm_email_banner_text_success') }}</b-alert>
        </div>
        <!-- Confirm Email Fail -->
        <div v-else class="confirm-email-fail-banner-container pt-3">
          <b-alert variant="danger" dismissible fade show>
            <div v-html="$t('confirm_email_banner_text_fail', { 'tag_open': '<a href=\'/my-account/dashboard\' class=\'alert-danger\'><u>', 'tag_close': '</u></a>' })"></div>
          </b-alert>
        </div>
      </div>
      <div v-if="passwordRest" class="confirm-email-success-banner-container pt-3">
        <b-alert variant="success" dismissible fade show>{{ $t('changed_password') }}</b-alert>
      </div>
    </b-container>

    <!-- Headline -->
    <Banner/>
    <TvAd/>

    <!-- Task Showcase -->
    <TaskShowcase location="home" class="my-4 py-2"/>

    <!-- stats bar -->
    <div class="bg-primary py-5 main-container">
      <div class="container">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-4 mt-3 mb-3 mt-md-3 stats d-flex flex-column align-items-center text-white">
              <p class="mw-100 mb-0">{{ $t('home.stats_bar.point1.subcopy') }}</p>
              <p class="mw-100 mb-0">{{ $t('home.stats_bar.point1.copy') }}</p>
            </div>
            <div class="col-12 col-md-4 mt-3 mb-3 stats d-flex flex-column align-items-center text-white">
              <p class="mw-100 mb-0">{{ $t('home.stats_bar.point2.subcopy') }}</p>
              <p class="mw-100 mb-0">{{ $t('home.stats_bar.point2.copy') }}</p>
            </div>
            <div class="col-12 col-md-4 mt-3 mb-3 stats d-flex flex-column align-items-center text-white">
              <p class="mw-100 mb-0">{{ $t('home.stats_bar.point3.subcopy') }}</p>
              <p class="mw-100 mb-0">{{ $t('home.stats_bar.point3.copy') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Popular Categories -->
    <div class="main-container my-5 py-2">
      <div class="top-categories">
        <div class="mb-4">
          <h3 class="font-weight-bold pb-1 pl-3 ml-3 pl-lg-0 homepage-title text-left">{{ $t('home.popular_content.title') }}</h3>
        </div>
        <div class="position-relative pl-2 pl-lg-0 pb-2 top-category-container d-flex justify-content-start justify-content-lg-between">
          <div class="mx-2 mx-xl-0" v-for="(category, index) in topCategories" :key="index">
            <categoryCard :imgWidth="100" :imgHeight="230" :image="getAsset(category.image_path)" :title="category.name" :titleEnglish="category.name_en" :slug="category.slug"
                     :route="category.hash === 'adminf4837jdw' ? '/category/' + category.slug + '/blackfridaytasks' : '/category/' + category.slug"/>
          </div>
        </div>
      </div>
    </div>

    <!-- blue bar categories-->
    <div class="blue-bar py-5 my-5 px-3 px-md-0">
      <div class="col-xl-12 my-3 pb-4 px-5">
        <h3 class="font-weight-bold text-white">{{ $t('home.blue_bar_categories.title') }}</h3>
      </div>
      <div class="container py-0 px-0 px-lg-5">
        <div class="flex flex-row justify-content-lg-between justify-content-around w-100">
          <a href="/category/design" class="d-flex flex-col text-white category-icon">
            <i class="fa-solid fa-pencil fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.visual_design') }}</h5>
          </a>
          <a href="/category/marketing" class="d-flex flex-col text-white category-icon">
            <i class="fa-solid fa-bullhorn fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.marketing') }}</h5>
          </a>
          <a href="/category/writing-and-translation" class="d-flex flex-col text-white category-icon">
            <i class="fa-solid fa-font fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.writing_and_translation') }}</h5>
          </a>
          <a href="/category/video-animation" class="d-flex flex-col text-white category-icon">
            <i class="fa-solid fa-camera-retro fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.photography_video_and_animation') }}</h5>
          </a>
          <a href="/category/programming-and-tech" class="d-flex flex-col text-white category-icon">
            <i class="fa-solid fa-code fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.programming_and_tech') }}</h5>
          </a>
          <a href="/category/nft-artists" class="d-flex flex-col text-white category-icon">
            <i class="fa-solid fa-palette fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.nft_and_digital_art') }}</h5>
          </a>
          <a href="/category/music-and-audio" class="flex-col text-white category-icon d-none d-md-flex">
            <i class="fa-solid fa-music fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.music_and_audio') }}</h5>
          </a>
          <a href="/category/business-and-data" class="flex-col text-white category-icon d-none d-md-flex">
            <i class="fa-solid fa-database fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.business_and_data') }}</h5>
          </a>
          <a href="/category/lifestyle" class="flex-col text-white category-icon d-none d-lg-flex">
            <i class="fa-solid fa-person-rays fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.lifestyle') }}</h5>
          </a>
          <a href="/" @click.prevent="setCategoryFilter(null), setSearchOverlay(true)" class="flex-col text-white category-icon d-none d-lg-flex">
            <i class="fa-solid fa-star-of-life fa-2xl pb-4 mb-1"></i>
            <h5>{{ $t('home.blue_bar_categories.all_tasks') }}</h5>
          </a>
        </div>
      </div>
    </div>

    <!--  Video Section  -->
    <div class="main-container py-0 my-5">
      <div class="container">
        <div class="d-flex flex-col flex-lg-row video-section">
          <div class="mx-2 mx-lg-o mr-lg-5 left order-2 order-lg-1">
            <h3 class="text-left mt-3 mb-4 mt-lg-0 pb-2 mb-lg-4 font-weight-bold text-center text-md-left">{{ $t('home.video_section.title') }}</h3>
            <div class="d-flex align-items-start justify-content-start bullet">
              <div>
                <i class="fa-solid fa-wallet fa-2xl mr-3 mr-md-4 mt-3 pt-1"></i>
              </div>
              <div class="d-flex flex-col align-items-start mb-2">
                <h4 class="m-0 mb-1">{{ $t('home.video_section.bullet_1.title') }}</h4>
                <p class="text-left">{{ $t('home.video_section.bullet_1.content') }}</p>
              </div>
            </div>

            <div class="d-flex align-items-start justify-content-start bullet">
              <div>
                <i class="fa-solid fa-person-rays fa-2xl mr-3 mr-md-4 mt-3 pt-1"></i>
              </div>
              <div class="d-flex flex-col align-items-start mb-2">
                <h4 class="m-0 mb-1">{{ $t('home.video_section.bullet_2.title') }}</h4>
                <p class="text-left">{{ $t('home.video_section.bullet_2.content') }}</p>
              </div>
            </div>

            <div class="d-flex align-items-start justify-content-start bullet">
              <div>
                <i class="fa-solid fa-shield-halved fa-2xl mr-3 mr-md-4 mt-3 pt-1"></i>
              </div>
              <div class="d-flex flex-col align-items-start">
                <h4 class="m-0 mb-1">{{ $t('home.video_section.bullet_3.title') }}</h4>
                <p class="text-left m-0">{{ $t('home.video_section.bullet_3.content') }}</p>
              </div>
            </div>
          </div>
          <b-button @click="showVideo = true" class="button order-1 order-lg-2 mb-4 mb-lg-5 mb-lg-0">
            <img class="w-100 rounded-lg" :src="require('@/assets/Home/video_section_thumbnail.jpg')">
          </b-button>
          <div @click="showVideo = false" v-if="showVideo" class="video-overlay">
            <div class="vid-container d-flex justify-content-center align-items-center">
              <iframe allow="autoplay" class="responsive-iframe" src="https://www.youtube.com/embed/ZDNqJQb7rkQ?si=NuY3VR6fVI3qv3es?mute=1&autoplay=1"></iframe>
            </div>
          </div>
        </div>
        <div v-if="!isLoggedIn" class="w-100 d-flex justify-content-center justify-content-md-start">
          <b-button  v-b-modal.signup pill variant="primary" class="mt-5 mt-lg-3 py-2 col-11 col-md-3 col-lg-2" type="submit"><h5 class="m-0">{{ $t('home.video_section.cta') }}</h5></b-button>
        </div>
      </div>
    </div>

    <!-- Reviews -->
    <div class="main-container py-0 my-5 testimonials">
      <div class="container">
        <div class="col-12">
          <div class="row row-featured row-featured-category">
            <div class="col-12 homepage-title text-center">
              <div class="inner">
                <h3 class="font-weight-bold d-inline-flex p-0 mt-3 mb-3 homepage-title">{{ $t('home.testimonials_carousel.title') }}</h3>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <b-carousel id="testimonials-carousel" v-model="fade" :interval="8000" controls fade>
              <!--herbertedukon-->
              <b-carousel-slide caption-html img-alt="herbertedukon's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/3a1e8caa50989946/task/1671457021120_Blue3DModernFreelancerTipsChecklistFacebookPost.jpg">
                <p class="quote mb-0">&ldquo;Work delivered as requested. Revision as requested. Very happy with this Seller's effort and attention to detail&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">deal</p>
                <router-link to="/herbertedukon/i-will-be-your-virtual-assistant-for-2-hours-a-day">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/3a1e8caa50989946/profile/1671458449938_20211108_111436.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">I Will Be Your Virtual Assistant For 2 Hours A Day<span class="name font-weight-bold font-italic">&nbsp;- herbertedukon</span>
                      </p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star-half-stroke"></span>
                        </div>
                        <div>4.9 (16 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--biswajitnandi99-->
              <b-carousel-slide caption-html img-alt="biswajitnandi99's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/a9ee9ade7ba1816c/task/1603991882428_codecanyon_envato_php_script_wordpress_theme_installation_and_bug_fix.jpg">
                <p class="quote mb-0">&ldquo;Great Service - Great Work Everything went better than I had anticipated. Thank you big time!!!&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">manzemail</p>
                <router-link to="/biswajitnandi99/install-any-codecanyon-php-script-and-fix-any-error">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/a9ee9ade7ba1816c/profile/1603991977910_36522944_2065620057038396_2606698364422586368_n.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">install any codecanyon PHP script and fix any error<span class="name font-weight-bold font-italic">&nbsp;- biswajitnandi99</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star-half-stroke"></span>
                        </div>
                        <div>4.9 (16 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--hafizalihassan-->
              <b-carousel-slide caption-html img-alt="hafizalihassan's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/a38cf3ed1f989e98/task/1608123681477_Untitled-1rrtrt.jpg">
                <p class="quote mb-0">&ldquo;The work was done fast and with expertise. I totally recommend.&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">erickmuchiri11</p>
                <router-link to="/hafizalihassan/mascot-logo-designgaming-sportsgaming-channel">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/a38cf3ed1f989e98/profile/1667992999962_FB_IMG_1667992968070.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">I will design a creative and distinctive logo for your business<span class="name font-weight-bold font-italic">&nbsp;- hafizalihassan</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star-half-stroke"></span>
                        </div>
                        <div>4.7 (161 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--laboginer-->
              <b-carousel-slide caption-html img-alt="laboginer's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/82f05a8d3177d60f/task/1605040335255_designcreativesimpleminimalistlogoforbusinessbrandidentitywithin24hours1.jpg">
                <p class="quote mb-0">&ldquo;Super friendly, very fast, great work Made from small idea at the beginning to a final design in only 24 hours. Thank you for your work and your cooperation. Thanks from Germany to You.&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">solidrocket</p>
                <router-link to="/laboginer/i-will-design-creative-simple-minimalist-logo-for-business-brand-identity-within-24-hours">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/82f05a8d3177d60f/profile/1604139906908_AnytaskPic.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">I will design and customize a fully responsive Wordpress website<span
                        class="name font-weight-bold font-italic">&nbsp;- laboginer</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star-half-stroke"></span>
                        </div>
                        <div>4.9 (10 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--bodmas-->
              <b-carousel-slide caption-html img-alt="bodmas's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/882e33da471c8672/gig/ufxgv6kkabu5em0ytgdp.jpg">
                <p class="quote mb-0">&ldquo;The task was ready in half a day and didn't need any correction. I only gave a brief description and he did the rest, freestyle. Impressive!&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">metalteun</p>
                <router-link to="/bodmas/i-will-design-2-professional-business-logo-with-unlimited-revisions">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/882e33da471c8672/profile/tdyvraknkeaxhakbg5q2.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">I will create a unique and eye catching logo for you<span
                        class="name font-weight-bold font-italic">&nbsp;- bodmas</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star-half-stroke"></span>
                        </div>
                        <div>4.8 (31 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--jannatulsadia0-->
              <b-carousel-slide caption-html img-alt="jannatulsadia0's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/64914d76e8b70c10/task/1625037045137_pencil-01.jpg">
                <p class="quote mb-0">&ldquo;Thank you for your professionalism and quick delivery. I will definitely use again.&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">matthew1180</p>
                <router-link to="/jannatulsadia0/cute-and-unique-icon-illustration-chibi-manga-cartoon-art-kid-art">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/64914d76e8b70c10/profile/1662614676093_9k07aj.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">cute and unique icon illustration and chibi.<span
                        class="name font-weight-bold font-italic">&nbsp;- jannatulsadia0</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star-half-stroke"></span>
                        </div>
                        <div>4.9 (47 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--vent923-->
              <b-carousel-slide caption-html img-alt="vent923's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/3ab73a4887e787b7/task/1656127112431_SampleWork_SketchestoDWG-1.jpg">
                <p class="quote mb-0">&ldquo;Great communication, excellent quality! Highly recommend!&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">paulasadov</p>
                <router-link to="/vent923/i-will-convert-your-plans-into-dwg-or-in-autocad">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/3ab73a4887e787b7/profile/1656127947864_69458253_2524653180913974_4812911738825474048_n.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">I will convert your plans into DWG or in AutoCAD<span
                        class="name font-weight-bold font-italic">&nbsp;- vent923</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                        </div>
                        <div>5.0 (6 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>

              <!--writerandeditor-->
              <b-carousel-slide caption-html img-alt="writerandeditor's task image" img-src="https://images.anytask.com/c_fill,h_425,q_auto,w_680/resources/user/1381d526b49d1e57/task/1628203965473_creativewritercreativewritingstorywritingcreativewritingessaysariclesummarystorynovele-book.jpg">
                <p class="quote mb-0">&ldquo;The ghost writing and editing was done above my expectation. I will be using these services again in the future.&rdquo;</p>
                <div class="stars">
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                  <span class="fa-solid fa-star"></span>
                </div>
                <p class="author font-italic d-inline-flex position-relative mb-2 pt-3">rmoon4</p>
                <router-link to="/writerandeditor/i-will-take-on-any-creative-writing-project">
                  <div class="seller d-flex">
                    <img class="avatar" src="https://images.anytask.com/c_fill,h_300,q_auto,w_300/resources/user/1381d526b49d1e57/profile/1613245412386_wewriterandeditorstorywritngghostwritingeditingproofreadingbookeditingbetareading.jpg"
                         width="50" height="50">
                    <div class="info d-flex flex-column text-left pr-2">
                      <p class="title mb-0">I will take on any creative writing project.<span
                        class="name font-weight-bold font-italic">&nbsp;- writerandeditor</span></p>
                      <div class="reviews d-flex font-weight-bold">
                        <div class="mr-1">
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                          <span class="fa-solid fa-star"></span>
                        </div>
                        <div>5.0 (10 reviews)</div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>

    <img class="d-md-none w-50 mx-auto" v-lazy="require('../assets/Home/trustpilot_mobile.png')" alt="Exellent 4.4 star Trustpilot">
    <img class="trust-pilot d-none d-md-block w-50 mx-auto" v-lazy="require('../assets/Home/trustpilot_desktop.png')" alt="Excellent 4.4 star Trustpilot">

    <img v-if="tracking_switch" src="https://tags.w55c.net/rs?id=7c78e2b6ae0f4a7596168b2870de6989&t=homepage"/>

    <!-- Login Modal -->
    <LoginModal :reason="$t('session_timeout')" id="SessionTimedOut"/>
  </div>
</template>

<script>
import categoryCard from '../components/Home/CategoryCard.vue'
import apiService from '../apiService.js'
import Banner from '../components/Home/Banner'
import TvAd from '../components/Home/TvAd'
import LoginModal from '../components/Global/Login'
import config from '../config'
import helper from '../helper'
import { mapActions } from 'vuex'
import TaskShowcase from '../components/Global/TaskShowcase.vue'

export default {
  name: 'home',
  props: {
    image: Image,
    title: String,
    text: String
  },
  metaInfo () {
    return {
      title: 'AnyTask - Thousands of affordable professional services',
      description: 'Exceptional digital tasks from talented freelancers in the unbanked community',
      link: [
        {
          rel: 'canonical',
          href: window.location.protocol + '//' + window.location.host + '/'
        }
      ],
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'AnyTask - Thousands of affordable professional services'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://images.anytask.com/image/upload/b_rgb:ffffff,c_lpad,co_rgb:ffffff,f_jpg,h_630,w_1200/urbiknsbdw7r5mpntg5w.jpg'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Exceptional digital tasks from talented freelancers in the unbanked community'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            'name': 'AnyTask',
            'logo': 'https://images.anytask.com/image/upload/c_scale,w_800/urbiknsbdw7r5mpntg5w.jpg',
            'sameAs': [
              'https://www.facebook.com/anytaskofficial',
              'https://twitter.com/anytaskofficial',
              'https://www.instagram.com/anytaskofficial',
              'https://www.linkedin.com/showcase/anytaskofficial',
              'https://www.youtube.com/anytask'
            ]
          }
        }
      ]
    }
  },
  data () {
    return {
      confirmEmailBanner: null,
      categories: [],
      popularTasks: [],
      parentCategories: [],
      passwordRest: false,
      tracking_switch: config.tracking_enabled === 'true',
      slide: 0,
      sliding: null,
      fade: null,
      searchInput: '',
      meetTheSellersLink: 'meet-the-sellers',
      showVideo: false,
      topCategorySlugs: ['design/logo-design', 'design/photo-editing-and-retouching', 'video-animation/video-editing', 'business-and-data/data-entry', 'marketing/seo', 'marketing/social-media-advertising'],
      topCategories: []
    }
  },
  components: {
    Banner,
    LoginModal,
    TaskShowcase,
    TvAd,
    categoryCard
  },
  beforeMount () {
    if (this.$route.name === 'influencer') {
      let name = this.$route.params.influencer
      this.$router.push('/?utm_source=' + name + '&utm_medium=go_redirect')
    }
  },
  mounted () {
    this.getAllCategories()
    this.setConfirmEmailBannerStatus()
    if (localStorage.getItem('loggedOut')) {
      this.$bvModal.show('SessionTimedOut')
      localStorage.removeItem('loggedOut')
    }
    if (location.hash === '#login_sso') {
      this.$bvModal.show('loginSso')
    }

    if (location.hash === '#passwordRest') {
      this.passwordRest = true
    }

    if (location.hash === '#login') {
      this.displayLogin()
    }

    if (location.hash === '#join') {
      this.displayJoin()
    }
    helper.reorderMetas()
  },
  methods: {
    ...mapActions([
      'processJwt',
      'setCategoryFilter',
      'setSearchOverlay'
    ]),
    getAsset (file) {
      try {
        return require('../assets/Categories/' + file)
      } catch (error) {
      }
    },
    getAllCategories () {
      apiService.getAllCategories().then((data) => {
        this.categories = data.data.data
        this.getTopCategories()
      }).catch(() => {
      })
    },
    getTopCategories () {
      let topCategories = []
      for (let index = 0; index < this.topCategorySlugs.length; index++) {
        for (let i = 0; i < this.categories.length; i++) {
          let target = this.categories[i].child_categories.findIndex((x) => x.slug === this.topCategorySlugs[index])
          if (target > -1) {
            topCategories.push(this.categories[i].child_categories[target])
          }
        }
      }
      this.topCategories = topCategories
    },
    setConfirmEmailBannerStatus () {
      if (this.$store.state.emailConfirmTokenSuccessful !== null) {
        this.confirmEmailBanner = this.$store.state.emailConfirmTokenSuccessful
        this.$store.state.emailConfirmTokenSuccessful = null
      }
    },
    onSubmitSearch () {
      if (this.searchInput.length > 0) {
        this.searchInput = this.searchInput.replace(/[^\w\s+]/gi, '')
      }
      this.$router.push({ name: 'search', query: { terms: this.searchInput } })
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    },
    displayLogin () {
      if (!localStorage.getItem('accessToken')) {
        this.$bvModal.show('login')
      }
    },
    displayJoin () {
      if (!localStorage.getItem('accessToken')) {
        this.$bvModal.show('signup')
      }
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.accessToken !== null
    }
  }
}
</script>

<style lang="scss" scoped>

.top-category-container {
  width: 100vw;
  padding-inline: 20px !important;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.blue-bar {
  background-color: $brand-sky;
  a:hover {
    text-decoration: none;
  }

  .category-icon {
    width: 130px;
    margin: 20px 20px;
    align-items: center;

    h5 {
      width: 145px;
    }
  }
}

.card-holder {
  display: flex;
  flex-wrap: wrap;
}

.video-section {
  .left {
    .bullet {
      i {
        width: 36px;
      }
      h4 {
        font-size: 1.2rem;
        font-weight: bold;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }

  .button {
    padding: 0;
    background-color: transparent;
    border: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
  .video-overlay {
    background-color: rgba(0,0,0,0.9);
    position: fixed;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    .vid-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
      .responsive-iframe {
        position: absolute;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}

  .card-holder {
    display: flex;
    flex-wrap: wrap;
  }

  .card-item {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;

    div {
      width: 100%;
    }
  }

  .meet-sellers {
    border-top: 1px solid rgba(180, 180, 180, 0.35);
  }

  .secondary-search {
    border: 1px solid rgba(44, 62, 80, 0.35);
    border-radius: .25rem;

    .form-control {
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.35);
    }
  }

  .search-row {
    max-width: none;
  }

  .home-category.outer-card:after {
    background: none;
  }

  .box-title h2 {
    font-size: 1.25rem;
    text-transform: none;
  }

  .home-categories {
    margin-left: -8px;
    margin-right: -8px;
  }

  .home-category {
    padding-left: 8px;
    padding-right: 8px;
  }

  .featured-tasks .card {
    height: 97%;
  }

  .sellers-link {
    a {
      width: 100%;

      &:hover {
        text-decoration: none;
      }

      button {
        &:hover {
          background-color: darken($brand-sky, 10%) !important;
          border-color: darken($brand-sky, 10%) !important;
        }
      }
    }
  }

  #protection-disclaimer {
    font-size: 90%;
  }

  @include md-up {
    .sellers-link {
      a {
        width: auto;
      }
    }
  }

</style>
<style lang="scss">

.featured-tasks {
  .row {
    margin: 0;

    > div {
      width: 100%;
    }
  }

  .loading {
    .column {
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      background: #fff;
      margin: 10px 0;
      border: 1px solid rgba(0, 0, 0, 0.125);
      min-height: 329px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.owl-carousel {
  .owl-stage {
    height: auto;
  }

  .owl-item {
    min-height: 100%;
    height: 100%;

    > div {
      height: 100%;
    }
  }
}

.owl-carousel .owl-dots {
  margin-top: 1rem;
}

.owl-theme .owl-nav {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: -1;
}

@include sm-up {

  .featured-tasks {
    .loading {
      .column {
        width: 48%;
        margin-right: 20px;
        height: 369px;

        &:nth-child(2) {
          margin-right: 0
        }
      }
    }
  }
}

@include md-up {
  .top-categories {
    max-width: 1120px;
    margin-inline: auto;
    overflow: visible;

    .top-category-container {
      padding-inline: 20px !important;
      -ms-overflow-style: auto;
      scrollbar-width: auto;
      width: 100% !important;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }

  .featured-tasks {
    .loading {
      .column {
        width: 31.35%;
        margin-right: 20px;
        height: 329px;

        &:nth-child(2) {
          margin-right: 20px;
        }

        &:nth-child(3) {
          margin-right: 0
        }
      }
    }
  }
  .owl-theme .owl-nav {
    display: block;
  }
}

@include lg-up {
  .featured-tasks {
    .loading {
      .column {
        width: 23.38%;
        height: 329px;

        &:nth-child(2) {
          margin-right: 20px;
        }

        &:nth-child(3) {
          margin-right: 20px;
        }

        &:nth-child(4) {
          margin-right: 0;
        }
      }
    }
  }

  .video-section {
    .left {
      width: 50%;
    }
    .button {
      width: 70%;
    }
    .video-overlay {
      .vid-container {
        .responsive-iframe {
          width: 60% !important;
          height: 60% !important;
        }
      }
    }
  }
}

@include xl-up {
  .top-category-container {
    overflow: visible !important;
  }
  .blue-bar {

    .homepage-title {
      font-size: 2.2rem !important;
    }

    .category-icon {
      width: 160px !important;
      margin-bottom: 40px !important;
    }
  }
  .featured-tasks {
    .loading {
      .column {
        width: 23.65%;
        height: 369px;

        &:nth-child(2) {
          margin-right: 20px;
        }

        &:nth-child(3) {
          margin-right: 20px;
        }

        &:nth-child(4) {
          margin-right: 0
        }
      }
    }
  }
}

.featured-tasks {
  .card {
    .review-and-price {
      background: transparent;
    }
  }

  .owl-carousel {
    .owl-nav {
      button {
        &.owl-prev, &.owl-next {
          position: absolute;
          width: 20px;
          height: 20px;
          padding: 0;
          margin: 0 !important;
          background-color: transparent;
          top: calc(50% - 30px);
        }

        &.owl-prev {
          left: -30px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23222' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
        }

        &.owl-next {
          right: -30px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23222' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
        }
      }
    }
  }

  .owl-theme .owl-nav [class*='owl-']:focus {
    outline: none;
  }

  .owl-theme .owl-nav [class*='owl-']:hover {
    color: transparent;
  }
}

.outer-card:after {
  background: linear-gradient(165deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%) !important;
}

.top-left.card-title {
  margin: 0;
  color: #fff;
  z-index: 5;
  display: flex;
  padding: 8px;
  right: 0;
  bottom: 0;

  h5 {
    text-align: left;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .outer-card .card-img {
    width: 100% !important;
  }
}

.selling-points img {
  max-height: 100px;
}

.selling-points p:last-of-type {
  font-size: 14px;
}

@media (min-width: 576px) {
  .selling-points p {
    max-width: 305px !important;
  }
}

.stats p:nth-child(2) {
  font-size: 2rem;
  line-height: 1.25;
}

.testimonials {
  background: white;
  color: #2c3e50;
}

#testimonials-carousel {
  border-radius: 0;
}

#testimonials-carousel .carousel-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
  overflow: hidden;
  border-radius: 10px;
}

@media (min-width: 576px) {
  #testimonials-carousel {
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(180, 180, 180, 0.5);
    border: 1px solid rgba(180, 180, 180, 0.35);
  }
}

@media (min-width: 768px) {
  #testimonials-carousel .carousel-item {
    flex-direction: row;
  }
}

.quote {
  font-size: 14px;
}

.author::before {
  content: '';
  border-top: 1px solid;
  position: absolute;
  left: -25%;
  width: 150%;
  top: 5px;
  color: #44aae1;
}

.stars {
  color: #ffbf00;
}

.seller {
  border: 1px solid #ccc;
  border-radius: 32px;
  align-items: center;
  padding: 5px;
  margin: 0;
  color: #2c3e50;
  max-width: 350px;
}

.seller::after {
  content: "\f0da";
  font-family: FontAwesome;
}

.seller:hover {
  text-decoration: none;
}

.seller a {
  color: #2c3e50;
}

.seller .title {
  font-size: 12px;
}

.seller .info {
  padding-right: 15px;
}

.seller .avatar {
  width: 50px !important;
  height: 50px;
  border-radius: 25px;
  margin: 2px 10px 2px 2px;
}

.seller .reviews {
  font-size: 10px;
}

.seller .reviews div:first-of-type {
  color: #ffbf00;
}

@media (min-width: 576px) {
  .seller {
    margin: 0 5%;
  }
}

@media (min-width: 768px) {
  .seller .title {
    font-size: 10px;
  }
  .quote {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .seller {
    margin: 0 3%;
  }
  .seller .title {
    font-size: 12px;
  }
  .quote {
    font-size: 16px;
  }
}

#testimonials-carousel .carousel-item img:not(.avatar) {
  width: 100% !important;
  border-radius: 10px;
}

#testimonials-carousel .carousel-item .carousel-caption {
  position: relative;
  color: #212529;
  width: 100%;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 18rem;
}

.trust-pilot {
  max-width: 400px;
}

#testimonials-carousel .carousel-item .carousel-caption a:hover {
  text-decoration: none;
}

#testimonials-carousel .carousel-item .carousel-caption a:hover .seller {
  background: #f4f4f4;
}

#testimonials-carousel .carousel-control-prev, #testimonials-carousel .carousel-control-next {
  display: flex;
  justify-content: center;
  background-color: transparent;
}

#testimonials-carousel .carousel-control-prev {
  left: -50px;
  display: none;
}

#testimonials-carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23222' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

#testimonials-carousel .carousel-control-next {
  right: -50px;
  display: none;
}

#testimonials-carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23222' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

@media (max-width: 320px) {
  #testimonials-carousel .carousel-item .carousel-caption {
    height: auto;
  }
}

@media (min-width: 576px) {
  #testimonials-carousel .carousel-item img:not(.avatar) {
    border-radius: 0;
  }
}

@media (min-width: 768px) {
  #testimonials-carousel .carousel-item .carousel-caption {
    height: auto;
    padding: 1%;
  }
  #testimonials-carousel .carousel-item img:not(.avatar) {
    width: 55% !important;
  }
  #testimonials-carousel .carousel-control-prev {
    display: flex;
  }
  #testimonials-carousel .carousel-control-next {
    display: flex;
  }
}

@media (min-width: 992px) {
  #testimonials-carousel .carousel-item .carousel-caption {
    height: auto;
    padding: 0 5%;
  }
  #testimonials-carousel .carousel-item img:not(.avatar) {
    width: 50% !important;
  }
}
</style>
