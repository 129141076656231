<template>
  <div>
    <div class="position-relative">
      <b-carousel id="home-banner" ref="homeBanner" :interval="bannerInterval" indicators fade>
        <b-carousel-slide>
          <div class="container h-100 d-flex flex-col justify-content-end justify-content-lg-center align-items-center align-items-sm-start pb-3 pb-sm-5 pb-lg-0">
            <h1 class="text-sm-left font-weight-bold">{{ $t('home.banner.list_job.title') }}</h1>
            <h5 class="mt-1 text-center text-sm-left">{{ $t('home.banner.list_job.subtitle') }}</h5>
            <b-button @click="bannerButtonTrack(), isLoggedIn ? $router.push({ name: 'job-create' }) : listJobLogin()" pill variant="primary" class="mt-3 mb-5 mb-md-0 px-5">{{ $t("home.banner.list_job.button") }}</b-button>
          </div>
        </b-carousel-slide>
        <b-carousel-slide>
          <div class="container h-100 d-flex flex-col justify-content-end justify-content-lg-center align-items-center align-items-sm-start pb-3 pb-sm-5 pb-lg-0">
            <h1 class="text-sm-left font-weight-bold">{{ $t('home.banner.talented_freelancers.title') }}</h1>
            <h5 class="mt-1 text-left">{{ $t('home.banner.talented_freelancers.subtitle') }}</h5>
            <div class="mt-2">
              <i alt="Mastercard" class="fa-brands fa-cc-mastercard fa-2xl mr-2"></i>
              <i alt="Visa" class="fa-brands fa-cc-visa fa-2xl mr-2"></i>
              <i alt="Amex" class="fa-brands fa-cc-amex fa-2xl mr-2"></i>
              <img height="24" src="../../assets/Crypto/etn.svg" alt="ETN" class="mr-2" style="width: 32px; height: 32px; margin-bottom: 4px;"/>
              <i alt="Bitcoin" class="fa-brands fa-bitcoin fa-2xl mr-1"></i>
              <img src="../../assets/Crypto/xrp-white.svg" alt="XRP" title="XRP" style="width: 32px; color: white; margin-bottom: 4px; margin-left: 3px;"/>
              <img src="../../assets/Crypto/bnb_logo.svg" alt="BNB" title="BNB" style="width: 32px; color: white; margin-bottom: 4px; margin-left: 7px;"/>
            </div>
            <b-button @click="setSearchOverlay(true)" pill variant="primary" class="mt-3 mb-5 mb-md-0 px-5">{{ $t("home.banner.talented_freelancers.button") }}</b-button>
          </div>
        </b-carousel-slide>
        <b-carousel-slide>
          <div class="container h-100 d-flex flex-col justify-content-end justify-content-lg-center align-items-center align-items-sm-start pb-3 pb-sm-5 pb-lg-0">
            <h1 class="text-sm-left font-weight-bold">{{ $t('home.banner.BNB.title') }}</h1>
            <h5 class="mt-1 text-left">{{ $t('home.banner.talented_freelancers.subtitle') }}</h5>
            <div class="mt-2">
              <i alt="Mastercard" class="fa-brands fa-cc-mastercard fa-2xl mr-2"></i>
              <i alt="Visa" class="fa-brands fa-cc-visa fa-2xl mr-2"></i>
              <i alt="Amex" class="fa-brands fa-cc-amex fa-2xl mr-2"></i>
              <img height="24" src="../../assets/Crypto/etn.svg" alt="ETN" class="mr-2" style="width: 32px; height: 32px; margin-bottom: 4px;"/>
              <i alt="Bitcoin" class="fa-brands fa-bitcoin fa-2xl mr-1"></i>
              <img src="../../assets/Crypto/xrp-white.svg" alt="XRP" title="XRP" style="width: 32px; color: white; margin-bottom: 4px; margin-left: 3px;"/>
              <img src="../../assets/Crypto/bnb_logo.svg" alt="BNB" title="BNB" style="width: 32px; color: white; margin-bottom: 4px; margin-left: 7px;"/>
            </div>
            <b-button @click="setSearchOverlay(true)" pill variant="primary" class="mt-3 mb-5 mb-md-0 mt-md-3 px-5">{{ $t("home.banner.talented_freelancers.button") }}</b-button>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import apiService from '../../apiService'
import config from '../../config'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      categories: [],
      bannerInterval: 4000
    }
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    ...mapActions([
      'setSearchOverlay'
    ]),
    listJobLogin () {
      localStorage.setItem('listJobLogIn', 'true')
      this.$bvModal.show('login')
    },
    bannerButtonTrack () {
      this.$tracker.tagEvent('listJobBannerButtonClick')
    },
    getCategories () {
      return apiService.getAllCategories(localStorage.getItem('language') === 'en' ? null : 'en').then((response) => {
        const responseSet = response.data['data']
        for (let x = 0; x < responseSet.length; x++) {
          this.categories.push(responseSet[x].name)
          for (let i = 0; i < responseSet[x]['child_categories'].length; i++) {
            this.categories.push(responseSet[x]['child_categories'][i].name)
          }
        }
        this.categories = [...new Set(this.categories)]
      })
    }
  },
  computed: {
    ...mapGetters(['getAdvanceSearch']),
    config () {
      return config
    },
    isLoggedIn () {
      return this.$store.state.accessToken !== null && this.$store.state.accessToken !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
}

::v-deep #home-banner {
  .carousel-caption {
    top: 0;
    right: 5%;
    left: 5%;
    height: 100%;
  }

  .carousel-indicators li {
    width: 25px !important;
  }

  .carousel-item {
    min-height: 590px;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;

    h1 {
      font-size: 2rem;
      width: 80%;
    }

    h5 {
      font-size: 1.2rem;
    }

    .btn-primary {
      font-size: 1.2rem;
      padding-block: 8px;
    }
    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 100), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
      content: ' ';
      left: 0;
    }
    @include sm-up {
      h1 {
        font-size: 2.6rem;
        width: 90%;
      }

      h5 {
        font-size: 1.5rem;
        width: 70%;
      }

      .btn-primary {
        font-size: 1.3rem;
        padding-block: 12px;
      }

      min-height: 60vh;
      &::after {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.85), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
      }
    }

    @include md-up {
      height: 65vh;
    }

    @include lg-up {
      h5 {
        width: 40%;
      }

      &::after {
        display: none;
      }
      @media (min-height: 1366px) {
        height: 35vh;
        min-height: unset;
      }
    }

    &:nth-child(1) {
      background-image: url('~@/assets/Home/Banners/list_job_mobile.png'), linear-gradient(to top, #6D56FE, #A0CBEB);
      @include sm-up {
        background-image: url('~@/assets/Home/Banners/list_job_tablet.png'), linear-gradient(to top, #6D56FE, #A0CBEB);
      }
      @include lg-up {
        background-image: url('~@/assets/Home/Banners/list_job_desktop.png'), linear-gradient(to top, #6D56FE, #A0CBEB);
      }
    }

    &:nth-child(2) {
      background-image: url('~@/assets/Home/Banners/TalentedFreelancers_mobile.png'), linear-gradient(to top, #DC679A, #3699D1);
      @include sm-up {
        background-image: url('~@/assets/Home/Banners/TalentedFreelancers_tablet.png'), linear-gradient(to top, #DC679A, #3699D1);
      }
      @include lg-up {
        background-image: url('~@/assets/Home/Banners/TalentedFreelancers_desktop.png'), linear-gradient(to top, #DC679A, #3699D1);
      }
    }

    &:nth-child(3) {
      background-image: url('~@/assets/Home/Banners/BNB_mobile.png'), linear-gradient(to top, #093F50, #5CC6C9);
      @include sm-up {
        background-image: url('~@/assets/Home/Banners/BNB_tablet.png'), linear-gradient(to top, #093F50, #5CC6C9);
      }
      @include lg-up {
        background-image: url('~@/assets/Home/Banners/BNB_desktop.png'), linear-gradient(to top, #093F50, #5CC6C9);
      }
    }
  }
}
</style>
