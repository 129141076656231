<template>
  <div>
    <b-alert v-if="!this.hiddenTVAdAlert" variant="announcement-banner" dismissible class="mb-0 bg-dark text-white"
        @dismissed="hideTvAd()"
        :show="!this.hiddenTVAdAlert">
      <div class="announcement-1">
        {{ $t('tv_advert_copy')}} <a class="d-block d-md-inline-block font-weight-bold text-white" v-b-modal.tv-clip>{{ $t('tv_advert_link') }}</a>
      </div>
    </b-alert>
    <NavBar :categories="categories" class="navbar"/>
    <div class="category-bar" :style="!showCategoryBar && `transform: translate3d(0, -${categoryBarHeight}px, 0);`">
      <div ref="categoryBar">
        <CategoriesNav  :categories="categories" class="d-none d-lg-block"/>
      </div>
      <div v-if="displayBecomeSellerBanner" class="nag-banner bg-secondary text-white d-flex w-100 position-relative align-items-md-center">
        <div class="container d-flex flex-column flex-md-row justify-content-around align-items-md-center py-3 py-md-2">
          <div class="d-flex flex-column mb-2 mb-md-0 pr-1">
            <h5 class="text-center text-md-left mb-1 mb-md-0 font-weight-bold">{{ $t('become_seller_button_join') }}</h5>
            <p class="text-center text-md-left mb-1 mb-md-0">{{ $t('become_seller_next_step') }}</p>
          </div>
          <router-link :to="{ name: 'connecting-etn' }" class="btn btn-outline-light w-100 px-3">{{ $t('connect_app.cta.continue') }}</router-link>
        </div>
        <span class="text-white mr-md-4 close cursor-pointer nag-banner-mobile-close" v-on:click="hideBecomeSeller()">×</span>
      </div>
    </div>

    <div v-if="accountLimitReached" class="nag-banner bg-secondary text-white d-flex w-100">
      <div class="container d-flex flex-column flex-md-row justify-content-around align-items-center py-3 py-md-2">
        <div class="d-flex flex-column mb-2 mb-md-0 pr-1">
          <h5 class="text-center text-md-left mb-1 mb-md-0 font-weight-bold">{{ $t('account_limit_banner.header') }}</h5>
          <p class="text-center text-md-left mb-1 mb-md-0">{{ $t('account_limit_banner.message') }}</p>
        </div>
        <b-button variant="outline-light" class="w-100 px-3" :href="myEtnLink" target="_blank" rel="noopener">{{ $t('account_limit_banner.button') }}</b-button>
      </div>
    </div>

    <div v-else-if="$store.state.user.verification === 2" class="nag-banner bg-secondary text-white d-flex w-100">
      <div class="container d-flex flex-column flex-md-row justify-content-around align-items-center py-3 py-md-2">
        <div class="d-flex flex-column mb-2 mb-md-0 pr-1">
          <h5 class="text-center text-md-left mb-1 mb-md-0 font-weight-bold">{{ $t('verification_banner.header') }}</h5>
          <p class="text-center text-md-left mb-1 mb-md-0">{{ $t('verification_banner.copy') }}</p>
        </div>
        <b-button variant="outline-light" class="w-100 px-3" :href="myEtnLink" target="_blank" rel="noopener">{{ $t('verification_banner.cta') }}</b-button>
      </div>
    </div>

    <EmailBanner :connecting-etn-process="connectingEtnProcess"/>

    <b-modal id="tv-clip">
      <!-- dismiss -->
      <span class="dismiss position-absolute" @click="$bvModal.hide('tv-clip')"><i class="fa fa-times" aria-hidden="true" /></span>
      <!-- modal content -->
      <div class="d-flex flex-column pt-3 pb-sm-3 pb-0 px-sm-3 px-sm-5 px-2">
        <h3 class="text-center font-weight-bold mb-3 pb-2 mt-2">{{ $t('tv_advert_modal_copy1')}}&nbsp;<span class="hashtag">{{ $t('tv_advert_modal_hashtag')}}</span></h3>
        <div class="tv-holder position-relative pb-sm-2 pb-md-5 mx-0 mx-sm-4">
          <div class="tv-mask position-absolute"></div>
          <iframe width="100%" class="w-100" :src="youtubeLink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <p class="text-center font-weight-bold mt-3">{{ $t('tv_advert_modal_copy2')}}</p>
        <div class="d-flex flex-row channels p-3 align-items-center justify-content-center">
          <img v-for="(channel,index) in channels" :key="index" :src="channel.image" :title="channel.name" class="m-3">
        </div>
        <div class="d-flex mb-0 mt-3">
          <b-button class="my-2 mx-auto btn-primary" @click="$bvModal.hide('tv-clip')">{{ $t('close_button')}}</b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import NavBar from '../../components/Navbar/NavBar'
import CategoriesNav from '../../components/Category/CategoriesNav'
import config from '../../config'
import EmailBanner from '../../components/Navbar/EmailBanner'

export default {
  name: 'header-item',
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  components: { CategoriesNav, NavBar, EmailBanner },
  data () {
    return {
      myEtnLink: config.etn_deeplink.app,
      connectingEtnProcess: false,
      hiddenBecomeSellerAlert: sessionStorage.getItem('banner-become-seller'),
      hiddenTVAdAlert: localStorage.getItem('banner-tv-ad'),
      showCategoryBar: true,
      lastScrollPosition: 0,
      categoryBarHeight: null,
      channels: [
        {
          name: 'cnbc',
          image: [require('../../assets/ChannelLogos/cnbc-white.png')]
        },
        {
          name: 'cnn',
          image: [require('../../assets/ChannelLogos/cnn_white.png')]
        },
        {
          name: 'espn',
          image: [require('../../assets/ChannelLogos/espn-news-white.png')]
        },
        {
          name: 'fox',
          image: [require('../../assets/ChannelLogos/fox-business-white.png')]
        },
        {
          name: 'mad',
          image: [require('../../assets/ChannelLogos/mav-tv-white.png')]
        },
        {
          name: 'newsmax',
          image: [require('../../assets/ChannelLogos/newsmax-white.png')]
        },
        {
          name: 'Sky News',
          image: [require('../../assets/ChannelLogos/sky-news-logo-white.png')]
        },
        {
          name: 'BBC',
          image: [require('../../assets/ChannelLogos/bbc-logo-white.png')]
        },
        {
          name: 'M-Net',
          image: [require('../../assets/ChannelLogos/m-net-logo-white.png')]
        },
        {
          name: 'eNCA',
          image: [require('../../assets/ChannelLogos/enca_logo-white.png')]
        }
      ],
      youtubeLink: 'https://www.youtube.com/embed/CnSFnfQJrhM'
    }
  },
  watch: {
    $route (to, from) {
      if (to.name !== from.from) {
        this.connectingEtnProcess = to.meta.connectingEtnProcess === true
      }
    }
  },
  mounted () {
    this.connectingEtnProcess = this.$route.meta.connectingEtnProcess === true
    window.addEventListener('scroll', this.onScroll)
    this.setCategoryBarHeight()
    window.addEventListener('resize', this.setCategoryBarHeight)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.setCategoryBarHeight)
  },
  methods: {
    setCategoryBarHeight () {
      this.categoryBarHeight = this.$refs.categoryBar.offsetHeight
    },
    onScroll () {
      const currentScrollPosition = window.scrollY || document.documentElement.scrollTop
      if (currentScrollPosition < 0 || Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showCategoryBar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    hideTvAd () {
      this.hiddenTVAdAlert = true
      localStorage.setItem('banner-tv-ad', 'true')
    },
    hideBecomeSeller () {
      this.hiddenBecomeSellerAlert = true
      sessionStorage.setItem('banner-become-seller', 'true')
    }
  },
  computed: {
    accountLimitReached () {
      let display = false
      if (this.$store.getters.isLoggedIn() && this.$store.state.user.band !== null) {
        this.$store.state.user.limits.forEach(value => {
          if (value.name === 'MAX_SELL_LIMIT' && value.limit_left === 0) {
            display = true
          }
        })
      }
      return display
    },
    displayBecomeSellerBanner () {
      if (this.hiddenBecomeSellerAlert) {
        return false
      }
      return this.$store.getters.hasRole('ROLE_PRE_SELLER') && !this.$store.state.user.verification && !this.connectingEtnProcess
    }
  }
}
</script>

<style lang="scss">
#tv-clip {
  .dismiss {
    right: 20px;
    top: 17px;
  }

  .close {
    color: green !important;
  }

  .modal-header, .modal-footer {
    display: none;
  }
  .modal-dialog {
    max-width: 720px;
    color: white !important;
    .tv-holder {
      @include md-up {
        background: radial-gradient(circle, rgba(1,1,1,1) 0%, rgba(24,41,52,1) 55%);
      }
      .tv-mask {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        @include md-up {
          background: url('../../assets/tv-holder.png');
          background-position: center top;
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
      iframe {
        width: 100%;
        height: 200px;
        @include sm-up {
          height: 300px;
        }
        @include md-up {
          height: 325px;
          margin-top: 2px;
        }
      }
    }
    h3 {
      color: white;
    }
    .hashtag {
      color: $brand-sky;
    }
    .channels {
      border-radius: 6px;
      background: lighten($brand-navy, 10%);
      border: 1px solid #2e4655;
      box-shadow: inset 0 0 12px rgba(0, 0, 0,.4);
      img {
        max-width: 85px;
      }
    }
  }
  .modal-body {
    background: $brand-navy;
    border-radius: 10px;
  }
  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    border: 0;
    background-color: transparent;
  }
}
</style>

<style lang="scss" scoped>

.navbar {
  z-index: 10;
}

.category-bar {
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  box-shadow: 0 -20px 15px 15px rgba(34, 34, 34, .5);
}

@keyframes shaker {
  from {
    transform: scale3d(1, 1, 1);
  }
  1%, 2% {
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -1deg);
  }
  3%, 5%, 7%, 9% {
    transform: scale3d(1.01, 1.01, 1.01) rotate3d(0, 0, 1, 1deg);
  }
  4%, 6%, 8% {
    transform: scale3d(1.01, 1.01, 1.01) rotate3d(0, 0, 1, -1deg);
  }
  10% {
    transform: scale3d(1, 1, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@include md-up {
  .nag-banner {
    .btn {
      width: auto !important;
      animation: shaker 10s;
      animation-iteration-count: infinite;
      animation-delay: 10s;
    }
  }
}

@media (min-width: 768px) {
  .sticky-top {
    margin: 0;
    top: auto;
  }
}

@media (max-width: 767px) {
  .nag-banner-mobile-close {
    position: absolute;
    top: 0;
    right: 5px;
  }
}

</style>
