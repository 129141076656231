export default {
  state: {
    newMessageThreads: [],
    newMessageCount: 0,
    newOrdersCount: 0,
    newDeliveriesCount: 0
  },
  getters: {
    getMessageThreads: (state) => () => {
      return state.newMessageThreads
    },
    hasMessageThread: (state) => (hash) => {
      return state.newMessageThreads.indexOf(hash) > -1
    },
    getNewMessageCount: (state) => () => {
      return state.newMessageCount
    },
    getNewOrdersCount: (state) => () => {
      return state.newOrdersCount
    },
    getNewDeliveriesCount: (state) => () => {
      return state.newDeliveriesCount
    },
    getUserMenuNotifications: (state) => () => {
      return state.newMessageCount + state.newOrdersCount + state.newDeliveriesCount
    }
  },
  mutations: {
    setMessageThreads: (state, data) => {
      state.newMessageThreads = data
    },
    setNewMessageCount: (state, data) => {
      state.newMessageCount = data
    },
    setNewOrdersCount: (state, data) => {
      state.newOrdersCount = data
    },
    setNewDeliveriesCount: (state, data) => {
      state.newDeliveriesCount = data
    }
  },
  actions: {
    setMessageThreads ({ commit }, data) {
      if (JSON.stringify(this.state.newMessageThreads) !== JSON.stringify(data)) {
        commit('setMessageThreads', data)
      }
    },
    setNewMessageCount ({ commit }, data) {
      commit('setNewMessageCount', data)
    },
    setNewOrdersCount ({ commit }, data) {
      commit('setNewOrdersCount', data)
    },
    setNewDeliveriesCount ({ commit }, data) {
      commit('setNewDeliveriesCount', data)
    }
  }
}
