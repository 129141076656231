<template>
  <div v-if="$store.getters.hasRole('ROLE_PRE_SELLER') && !connectingEtnProcess && !$store.state.user.emailConfirmed && $store.state.user.verification === 1" class="nag-banner bg-secondary text-white d-flex w-100">
    <div class="container d-flex flex-column flex-md-row justify-content-around align-items-center py-3 py-md-2">
      <div class="d-flex flex-column mb-2 mb-md-0 pr-1">
        <h5 class="text-center text-md-left mb-1 mb-md-0 font-weight-bold">{{ $t('become_seller_button_join') }}</h5>
        <p class="text-center text-md-left mb-1 mb-md-0">{{ $t('email_needs_confirming_banner') }}</p>
      </div>
      <b-button variant="outline-light" class="w-100 px-3" @click="resendEmail()" v-if="!apiCalled">{{ $t('confirm') }}</b-button>
      <b-button variant="outline-light" class="w-100 px-3" v-else><b-spinner small label="Small Spinner" /></b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiService from '@/apiService'

export default {
  name: 'EmailBanner',
  props: {
    connectingEtnProcess: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      apiCalled: false
    }
  },
  methods: {
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    resendEmail () {
      this.apiCalled = true
      ApiService.confirmEmail().then(() => {
        this.$swal({
          title: this.$t('success'),
          text: this.$t('email_confirmation__sent'),
          type: 'success',
          confirmButtonText: this.$t('close_button')
        })
        this.apiCalled = false
      }).catch((error) => {
        this.apiCalled = false
        switch (error.response.status) {
          case 400:
            this.$swal({
              text: this.$t('email_confirmation_already_sent'),
              type: 'info',
              confirmButtonText: this.$t('close_button')
            })
            break
          case 409:
            this.fetchNewAccessToken().then(() => {
              this.$swal({
                text: this.$t('email_confirmed'),
                type: 'success',
                confirmButtonText: this.$t('close_button')
              })
            })
            break
          default:
            this.$swal({
              text: this.$t('error_sending_email_confirmation'),
              type: 'warning',
              confirmButtonText: this.$t('close_button')
            })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
